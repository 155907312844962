import React from "react";
import moment from "moment";
import { Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import CustomButton from "components/UiComponents/CustomButton";
import CustomEmpty from "components/UiComponents/CustomEmpty";
import { DATE_FORMAT } from "utils/Constants/Constant";
import { getValueOrPlaceholder } from "utils/Helper/Services.helper";
import "./CustomTableCard.less";

const CustomTableCard = ({
  data,
  columns,
  loading,
  onChangePage,
  pagination,
  showPagination = true,
  excludeColumns = ["sNo", "name", "title", "amount"],
}) => {
  const renderColumnData = (item, column, index) => {
    if (column.key === "sNo") {
      return `${index + 1}`;
    }

    if (column.dataIndex) {
      if (!(column.dataIndex in item)) {
        return null;
      }
      const dataValue = item[column.dataIndex];
      if (column.key === "createdAt") {
        return moment(dataValue).format(DATE_FORMAT);
      }
      return dataValue;
    }

    if (typeof column.render === "function") {
      return column.render(item);
    }

    return null;
  };

  const filteredColumns = columns.filter(
    (column) => !excludeColumns.includes(column.key)
  );

  const totalPages = showPagination
    ? Math.ceil(pagination.total / pagination.pageSize)
    : 0;
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (page) => {
    onChangePage({ ...pagination, current: page });
  };

  if (data?.length === 0) {
    return <CustomEmpty description={<p> No Data Found...</p>} />;
  }

  return (
    <>
      <Spin
        spinning={loading ? loading : false}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 40,
              color: "#391f5b",
            }}
            spin
          />
        }
      >
        <div className="customTableCardWrapper">
          {data &&
            data.map((item, index) => (
              <div key={item.id || index} className="customTableCard">
                {item?.name && (
                  <h2 className="title">
                    {item.name.length > 40
                      ? item.name.slice(0, 40) + "..."
                      : item.name}
                  </h2>
                )}
                {item?.title && (
                  <h2 className="title">
                    {item.title.length > 40
                      ? item.title.slice(0, 40) + "..."
                      : item.title}
                  </h2>
                )}

                <ul>
                  {filteredColumns &&
                    filteredColumns.map((column) => (
                      <li key={column?.key} className={column?.className}>
                        <strong>{column.title}:</strong>{" "}
                        {renderColumnData(item, column, index)}
                      </li>
                    ))}
                </ul>

                {item?.amount !== undefined && (
                  <p className="amount">
                    {getValueOrPlaceholder(item, "amount")}
                  </p>
                )}
              </div>
            ))}
        </div>
      </Spin>

      {showPagination && data && data?.length > 0 && (
        <>
          <div className="current-page-info">
            <p>
              Page {pagination.current} of{" "}
              {Math.ceil(pagination.total / pagination.pageSize)}
            </p>
          </div>

          <div className="pagination-controls">
            <CustomButton
              onClick={() => handlePageChange(pagination.current - 1)}
              disabled={pagination.current <= 1}
              className="previous"
            >
              <LeftOutlined />
            </CustomButton>
            {pageNumbers.map((number) => (
              <CustomButton
                key={number}
                onClick={() => handlePageChange(number)}
                disabled={pagination.current === number}
                className={`${
                  pagination.current === number && "active"
                } number`}
              >
                {number}
              </CustomButton>
            ))}
            <CustomButton
              onClick={() => handlePageChange(pagination.current + 1)}
              disabled={pagination.current >= totalPages}
              className="next"
            >
              <RightOutlined />
            </CustomButton>
          </div>
        </>
      )}
    </>
  );
};

export default CustomTableCard;
