import React from "react";

function PlansIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M573 5106 c-178 -43 -325 -195 -362 -374 -15 -70 -16 -3754 -1 -3833
25 -135 131 -276 256 -340 81 -40 119 -42 151 -5 43 50 23 91 -61 129 -73 34
-140 97 -173 166 l-28 56 0 1905 0 1905 23 49 c37 80 85 132 160 168 l67 33
1404 3 c1543 3 1457 6 1553 -58 57 -38 95 -86 124 -159 36 -89 77 -110 129
-69 24 19 27 26 23 69 -15 153 -187 321 -366 358 -81 17 -2829 14 -2899 -3z"
        />
        <path
          d="M1190 4761 c-73 -23 -131 -74 -171 -151 -22 -41 -24 -57 -24 -195 0
-131 3 -156 21 -195 30 -65 69 -107 128 -138 l51 -27 835 0 835 0 51 27 c59
31 98 73 128 138 18 39 21 65 21 190 0 130 -3 150 -23 195 -28 60 -62 98 -122
133 l-45 27 -830 2 c-456 1 -841 -2 -855 -6z m1667 -161 c51 -31 58 -52 58
-185 0 -138 -12 -172 -69 -199 -31 -14 -113 -16 -815 -16 -850 0 -828 -1 -866
56 -15 23 -20 51 -23 134 -4 128 8 174 57 207 l34 23 796 0 c771 0 797 -1 828
-20z"
        />
        <path
          d="M3736 4437 l-26 -26 0 -1358 0 -1358 -478 -480 c-264 -264 -489 -497
-501 -517 l-23 -38 -892 0 c-626 0 -902 -3 -925 -11 -53 -19 -69 -81 -31 -119
20 -20 33 -20 905 -20 487 0 885 -3 885 -6 0 -4 -14 -54 -31 -112 -25 -82 -30
-116 -26 -157 13 -124 108 -219 232 -232 43 -5 80 3 211 42 87 25 186 60 219
78 51 26 179 150 836 806 627 627 781 786 800 826 18 40 23 67 23 135 0 118
-20 158 -141 282 -127 129 -174 153 -293 152 -132 0 -149 -12 -402 -263 l-218
-215 0 1281 c0 1205 -1 1282 -17 1302 -10 11 -32 24 -50 27 -26 5 -37 1 -57
-19z m812 -2272 c48 -21 179 -149 203 -200 43 -92 18 -154 -118 -287 l-98 -98
-183 183 -183 183 104 102 c135 132 185 154 275 117z m-295 -513 l177 -177
-90 -90 -90 -90 -182 183 -183 182 85 85 c46 47 89 85 95 85 6 0 90 -80 188
-178z m-545 -900 c-238 -238 -445 -442 -459 -452 l-25 -19 -179 179 -179 180
454 455 455 455 182 -182 183 -183 -432 -433z m-715 -564 c-124 -39 -154 -43
-191 -24 -32 16 -62 61 -62 91 0 9 14 66 32 126 l31 110 139 -138 138 -138
-87 -27z"
        />
        <path
          d="M3528 1043 c-122 -121 -138 -141 -138 -170 0 -43 30 -73 73 -73 29 0
49 16 170 138 115 116 137 143 137 169 0 37 -36 73 -75 73 -23 0 -54 -25 -167
-137z"
        />
        <path
          d="M1180 3595 l-205 -205 -72 72 c-58 57 -77 71 -102 71 -43 0 -71 -28
-71 -72 0 -31 11 -46 108 -143 88 -88 113 -108 137 -108 25 0 67 37 272 242
219 218 243 246 243 275 0 43 -30 73 -72 73 -28 0 -56 -24 -238 -205z"
        />
        <path
          d="M1884 3506 c-48 -21 -60 -80 -24 -116 20 -20 33 -20 664 -20 l645 0
20 26 c27 35 26 57 -3 91 l-24 28 -624 2 c-507 2 -629 0 -654 -11z"
        />
        <path
          d="M1013 2806 c-89 -29 -167 -99 -205 -184 -28 -61 -30 -188 -4 -247 57
-132 167 -206 306 -207 131 0 243 71 298 190 38 82 38 193 -1 273 -32 67 -100
132 -165 162 -59 25 -171 32 -229 13z m176 -156 c166 -84 107 -332 -79 -332
-124 0 -210 122 -166 236 38 100 151 144 245 96z"
        />
        <path
          d="M1865 2545 c-32 -31 -33 -74 -2 -103 l23 -22 638 0 637 0 24 25 c14
13 25 31 25 39 0 28 -22 64 -47 75 -17 8 -212 11 -649 11 l-625 0 -24 -25z"
        />
        <path
          d="M1020 1861 c-90 -29 -175 -105 -213 -189 -16 -35 -21 -67 -21 -127 0
-60 5 -92 21 -127 28 -62 96 -134 158 -166 44 -24 62 -27 145 -27 83 0 101 3
145 27 62 32 130 104 158 166 30 66 30 188 0 254 -29 63 -96 134 -158 166 -39
20 -68 26 -130 28 -44 2 -91 0 -105 -5z m188 -168 c92 -68 105 -183 31 -268
-37 -42 -86 -59 -151 -53 -105 11 -179 118 -149 219 14 47 55 97 96 115 43 20
138 13 173 -13z"
        />
        <path
          d="M1865 1595 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 l24 -25
634 0 c605 0 636 1 658 19 32 26 32 86 0 112 -22 18 -53 19 -658 19 l-634 0
-24 -25z"
        />
      </g>
    </svg>
  );
}

export default PlansIcon;
