import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/index.routes";
import { PageTitleContext } from "./PageTitleContext";
import { InfinitySpin } from "react-loader-spinner";
import { jwtDecode } from "jwt-decode";
import "assets/Fonts/Style";
import "./App.less";
import "./App.css";

function App() {
  const [pageTitle, setPageTitle] = React.useState("");
  const [backBtn, setBackBtn] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const token = localStorage.getItem("jwtToken");
  if (token && isTokenExpired(token)) {
    localStorage.removeItem("jwtToken");
    window.location.reload(); // Force a reload to reflect the change
  }

  function isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      return decoded.exp * 1000 < Date.now();
    } catch (err) {
      return true; // Consider the token as expired if there's any error decoding it
    }
  }

  useEffect(() => {
    function updateOnlineStatus() {
      setIsOnline(navigator.onLine);
    }

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <>
      <PageTitleContext.Provider
        value={{ pageTitle, setPageTitle, backBtn, setBackBtn }}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </PageTitleContext.Provider>

      {!isOnline && (
        <div className="noConnectionLoader loaderContainer">
          <InfinitySpin width="200" color="#013744" />
          <h2 className="noConnectionError">No Internet Connection</h2>
        </div>
      )}
    </>
  );
}

export default App;
