// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customAutoCompleteWrapper {
  height: 45px;
  display: inline-block;
  width: 100%;
}
.customAutoCompleteWrapper > .ant-select {
  height: 100%;
}
.customAutoCompleteWrapper input {
  color: #000000;
  font-size: 14px;
  font-family: "Roboto";
  padding: 8px 12px 8px 12px;
  height: 100%;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
}
.customAutoCompleteWrapper input::placeholder {
  color: #00000080;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomAutoComplete/CustomAutoComplete.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,qBAAA;EACA,WAAA;AADF;AAFA;EAKI,YAAA;AAAJ;AALA;EAQI,cAAA;EACA,eAAA;EACA,qBAAA;EACA,0BAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,cAAA;EACA,UAAA;AACN","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.customAutoCompleteWrapper {\n  height: 45px;\n  display: inline-block;\n  width: 100%;\n  > .ant-select {\n    height: 100%;\n  }\n  input {\n    color: @black-color;\n    font-size: 14px;\n    font-family: @primary-font-family;\n    padding: 8px 12px 8px 12px;\n    height: 100%;\n    font-weight: 400;\n    line-height: 1;\n    letter-spacing: 0em;\n    &::placeholder {\n      color: @placeholder;\n      font-size: 14px;\n      font-family: @primary-font-family;\n      font-weight: 400;\n      line-height: 1;\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
