import React, { memo } from "react";
import { Empty } from "antd";
import "./CustomEmpty.less";

const CustomEmpty = (props) => {
  return (
    <div className="emptyWrapper">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} {...props} />
    </div>
  );
};

export default memo(CustomEmpty);
