export const APP_NAME = "Service Management Portal Web App";

export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT = "LT / DD-MM-YYYY";
export const MONTH_FORMAT = "MM-YYYY";

export const pageSize = 10;
export const perPageLimit = 100;

export const NUMBER_PATTERN = {
  PATTERN: /^[-+]?[0-9]+\.[0-9]+$/,
};
export const phoneMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const CHARTDATA = {
  MONTHS: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  DAYS: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  COLORS: ["#391f5b ", "#3a3a3c", "#697077"],
  DATA: {
    PAYMENTS: [
      "0",
      "5",
      "10",
      "15",
      "20",
      "25",
      "30",
      "35",
      "40",
      "45",
      "50",
      "55",
      "60",
    ],
  },
};

export const ERROR = {
  UNAUTHENTICATED_USER: 401,
  FORBIDDEN_REQUEST: 403,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  EXIST: 417,
  TOO_MANY_REQUEST: 429,
};

export const PLAN_DURATION = [
  { label: "6 Months", value: "6-months" },
  { label: "12 Months", value: "12-months" },
  { label: "24 Months", value: "24-months" },
];

export const SERVICE_STATUS = [
  { label: "Active", value: 1, color: "#FF9800" },
  { label: "InActive", value: 0, color: "#9E9E9E" },
];

export const CONTRACT_STATUS = [
  { label: "Draft", value: 0, color: "#9E9E9E" },
  { label: "Sent", value: 10, color: "#2196F3" },
  { label: "Accepted", value: 11, color: "#4CAF50" },
  { label: "Active", value: 1, color: "#FF9800" },
  { label: "Cancelled", value: 12, color: "#E64A19" },
  { label: "Completed", value: 20, color: "#9C27B0" },
];

export const SERVICE_TYPE = [
  { label: "Purchasable Service", value: "Purchasable" },
  { label: "Nonpurchasable Service", value: "Nonpurchasable" },
  { label: "Custom", value: "Custom" },
];
export const DELIVERABLE = [
  { label: "None", value: 0 },
  { label: "Report", value: 1 },
];

export const ASSIGNEE = [
  { label: "Arif", value: "arif" },
  { label: "Faizan", value: "faizan" },
  { label: "Noman", value: "noman" },
  { label: "Mahasagar", value: "mahasagar" },
  { label: "Samad", value: "samad" },
];

export const USER_ROLE = [
  { label: "Admin", value: 1 },
  { label: "Account Manager", value: 2 },
  { label: "Project Manager", value: 3 },
  // { label: "Customer", value: 10 },
];

export const SORT = [
  { label: "Ascending", value: "ASC" },
  { label: "Descending", value: "DESC" },
];

export const CONTRACT_DURATION = [
  { label: "1 Month", value: 1 },
  { label: "Quarter Year (3 Months)", value: 3 },
  { label: "Half Year (6 Months)", value: 6 },
  { label: "1 Year (12 Months)", value: 12 },
  { label: "2 Years (24 Months)", value: 24 },
  { label: "3 Years (36 Months)", value: 36 },
  { label: "Custom", value: 0 },
];
export const BILLING_FREQUENCY = [
  { label: "One Time", value: 0 },
  { label: "Monthly", value: 1 },
  { label: "Quarterly", value: 3 },
  { label: "Half Yearly", value: 6 },
  { label: "Yearly", value: 12 },
];

export const START_CRITERIA = [
  { label: "Fixed Date", value: 1 },
  { label: "Upon Accepting Contract", value: 2 },
  { label: "Upon First Payment", value: 3 },
];

export const INVOICE_STATUS = [
  { label: "Pending", value: 0, color: "#9E9E9E" },
  { label: "Paid", value: 1, color: "#4CAF50" },
  { label: "Failed", value: 2, color: "#E64A19" },
];

export const REPORT_STATUS = [
  { label: "Draft", value: 0, color: "#9E9E9E" },
  { label: "Published", value: 1, color: "#4CAF50" },
];

export const TECH_PROCUREMENTS = [
  { label: "Active", value: 1 },
  { label: "InActive", value: 0 },
];

export const WEB_SERVICE_TYPE = [
  { label: "Domain", value: 1 },
  { label: "Hosting", value: 2 },
  { label: "Domain Hosting", value: 3 },
];

export const ACTIVITY_TYPE = [
  { label: "Created", value: 1 },
  { label: "Updated", value: 2 },
  { label: "Deleted", value: 3 },
];

export const ENTITY_TYPE = [
  { label: "User", value: 10, url: "user" },
  { label: "Role", value: 11, url: "roles" },
  {
    label: "Permission",
    value: 12,
    url: "permissions",
  },
  { label: "Customer", value: 20, url: "customers" },
  { label: "Project", value: 21, url: "project" },
  { label: "Service", value: 22, url: "service" },
  { label: "Plan", value: 23, url: "plan" },
  { label: "Report", value: 24, url: "report" },
  { label: "Contract", value: 31, url: "contract" },
  { label: "Invoice", value: 32, url: "invoice" },
  { label: "Attachment", value: 41, url: "attachments" },
  { label: "Activity", value: 42, url: "activity" },
  { label: "Tech Procurement", value: 61, url: "tech-procurements" },
  { label: "Web Service", value: 62, url: "web-service" },
];
