import { ENTITY_TYPE } from "utils/Constants/Constant";

export const getValueOrPlaceholder = (res, property, placeholder = "-") => {
  const value = res[property];
  if (value === undefined) {
    return placeholder;
  }
  if (property === "amount" && typeof value === "number") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  return value;
};

export const getLabelOrDefault = (lookupArray, value, propertyName) => {
  const foundObj = lookupArray.find((obj) => obj.value === value);
  return foundObj ? foundObj.label : `Unknown (${propertyName}: ${value})`;
};

export function getLabelById(valueArray, id) {
  if (!Array.isArray(valueArray)) {
    return "-";
  }

  const value = valueArray.find((value) => value.id === id);
  return value ? (value.title ? value.title : value.name) : "-";
}

export function generatePassword() {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
  let password = "";
  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    password += chars[randomIndex];
  }
  return password;
}

export const transformToOptions = (items, filterStatus = false) => {
  if (!items) return [];
  let filteredItems = items;
  if (filterStatus) {
    filteredItems = items.filter((item) => item.status !== 0);
  }
  return filteredItems.map((item) => ({
    label: item.name ? item.name : item.title,
    value: item.name ? item.name : item.title,
  }));
};

// To check if a URL is valid
const isValidURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

// To strip HTTPS protocol
const stripProtocol = (url) => {
  return url.replace(/^(https?:\/\/)?(.*?)(\/)?$/, "$2");
};

// To Render the domain with or without a link
export const renderDomain = (res) => {
  const domain = res?.domain;
  if (isValidURL(domain)) {
    return (
      <a href={domain} target="_blank" rel="noopener noreferrer">
        {stripProtocol(domain)}
      </a>
    );
  }
  return domain || "N/A";
};

export const getLabelByValue = (list, value) => {
  const entity = list.find((type) => type.value === value);
  return entity ? entity.label : "Unknown";
};

export const getEntityUrlByValue = (value) => {
  const entity = ENTITY_TYPE.find((item) => item.value === value);
  return entity ? entity.url : "";
};

// Color based on a string
export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(
    Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216)
  ).toString(16);
  return "#" + Array(6 - color.length + 1).join("0") + color;
};
