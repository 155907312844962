// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emptyWrapper p {
  color: #391f5b;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1;
  margin: 0;
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomEmpty/CustomEmpty.less"],"names":[],"mappings":"AAEA;EAEI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;EACA,iBAAA;AAFJ","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.emptyWrapper {\n  p {\n    color: @primary-color;\n    font-size: 14px;\n    font-weight: 400;\n    letter-spacing: 0em;\n    line-height: 1;\n    margin: 0;\n    padding-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
