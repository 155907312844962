import React from "react";

function ActivityIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M502 5101 c-185 -46 -349 -176 -429 -341 -79 -162 -73 5 -73 -2200 0
-2205 -6 -2038 73 -2200 58 -119 167 -229 287 -287 159 -78 6 -73 2200 -73
2194 0 2041 -5 2200 73 119 58 229 168 287 287 78 159 73 6 73 2200 0 2194 5
2041 -73 2200 -58 120 -168 229 -287 287 -162 79 6 73 -2207 72 -1871 0 -1982
-1 -2051 -18z m4071 -216 c81 -21 132 -50 194 -107 59 -56 106 -141 123 -221
7 -36 10 -642 8 -2027 l-3 -1975 -24 -58 c-43 -109 -139 -205 -248 -249 l-58
-23 -2005 0 -2005 0 -58 24 c-109 43 -205 139 -249 248 l-23 58 -3 1975 c-2
1385 1 1991 8 2027 25 118 117 240 221 294 99 50 6 48 2104 48 1709 1 1968 -1
2018 -14z"
        />
        <path
          d="M813 4286 c-158 -51 -265 -199 -264 -366 0 -87 17 -145 66 -215 42
-61 87 -98 163 -133 50 -24 69 -27 152 -26 81 0 104 4 153 27 153 70 249 248
219 409 -24 127 -104 232 -220 286 -68 32 -199 41 -269 18z m197 -228 c57 -39
75 -72 75 -137 -1 -48 -6 -65 -29 -96 -94 -123 -288 -59 -288 95 0 94 64 160
157 160 38 0 61 -6 85 -22z"
        />
        <path
          d="M1893 4281 c-213 -74 -315 -310 -224 -516 43 -98 122 -168 229 -204
62 -21 65 -21 1225 -19 l1162 3 67 32 c293 138 293 554 0 691 l-57 27 -1175 2
c-1110 2 -1178 2 -1227 -16z m2387 -224 c47 -31 72 -78 72 -136 0 -58 -21
-102 -64 -133 l-33 -23 -1150 0 -1150 0 -33 23 c-91 66 -88 205 8 269 l33 23
1142 0 1142 0 33 -23z"
        />
        <path
          d="M803 2921 c-247 -92 -334 -381 -178 -593 136 -184 425 -198 579 -28
55 62 83 120 98 199 30 159 -58 329 -209 403 -85 42 -207 50 -290 19z m207
-223 c57 -39 75 -72 75 -138 0 -92 -54 -150 -147 -157 -85 -7 -149 40 -168
120 -10 44 2 101 27 135 46 62 151 81 213 40z"
        />
        <path
          d="M1893 2921 c-269 -98 -345 -430 -144 -631 25 -25 73 -60 106 -76 l60
-29 1190 0 1190 0 58 28 c291 143 292 551 2 693 l-60 29 -1175 2 c-1126 2
-1177 2 -1227 -16z m2395 -229 c43 -31 64 -75 64 -132 0 -57 -21 -101 -64
-132 l-33 -23 -1130 -3 c-778 -2 -1141 0 -1167 8 -99 28 -135 167 -66 255 45
57 -13 55 1223 52 l1140 -2 33 -23z"
        />
        <path
          d="M825 1567 c-191 -64 -300 -232 -274 -422 16 -122 99 -234 214 -291
54 -26 70 -29 160 -29 88 0 107 3 157 27 245 115 301 425 111 616 -71 71 -144
103 -248 108 -50 3 -95 -1 -120 -9z m169 -221 c18 -8 46 -31 62 -51 37 -49 41
-137 8 -182 -37 -49 -81 -73 -139 -73 -93 0 -157 66 -157 160 0 117 116 192
226 146z"
        />
        <path
          d="M1895 1559 c-328 -113 -350 -559 -35 -708 l55 -26 1169 -3 c1288 -3
1222 -6 1320 59 222 147 224 486 3 632 -106 71 -34 67 -1306 67 -1131 -1
-1147 -1 -1206 -21z m2393 -227 c43 -31 64 -75 64 -133 0 -58 -25 -105 -72
-136 l-33 -23 -1139 0 c-1242 0 -1170 -3 -1217 56 -64 82 -39 203 51 247 30
15 137 16 1173 14 l1140 -2 33 -23z"
        />
      </g>
    </svg>
  );
}

export default ActivityIcon;
