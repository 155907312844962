import React, { memo } from "react";
import { Badge } from "antd";
import "./CustomStatusBadge.less";

const CustomStatusBadge = ({ status, contractStatus = [] }) => {
  const getStatusProperties = (statusValue) => {
    const statusObj = contractStatus.find(
      (status) => status.value === statusValue
    );
    return statusObj
      ? { label: statusObj.label, color: statusObj.color }
      : { label: "Unknown", color: "#391f5b" };
  };

  const { label, color } = getStatusProperties(status);

  return (
    <span className="customStatusBadge">
      <Badge count={label} style={{ backgroundColor: color, color: "white" }} />
    </span>
  );
};

export default memo(CustomStatusBadge);
