import React from "react";

function DashboardIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99401 7.90293L10.0043 7.70483L8.74425 9.78212C8.61318 10.0053 8.61811 10.2774 8.75711 10.4926C8.95329 10.7963 9.38903 10.8964 9.70833 10.7117C9.94309 10.5756 10.0772 10.327 10.0582 10.0629C10.0078 9.36198 9.95584 8.63761 9.99401 7.90293Z"
        fill="#63719C"
      />
      <path
        d="M9.19367 4.55399V3.27319C7.68411 3.34379 6.27909 3.93604 5.18213 4.96414L6.10054 5.87145C6.95121 5.08248 8.03076 4.62267 9.19377 4.55399H9.19367Z"
        fill="#63719C"
      />
      <path
        d="M9.5 0.0797267C4.2616 0.0797267 0 4.2909 0 9.46731C0 10.0384 0.0522613 10.6105 0.155551 11.168C0.182366 11.3133 0.30905 11.4185 0.456806 11.4185H18.5431C18.6908 11.4185 18.8175 11.313 18.8444 11.1679C18.9476 10.6104 19 10.0382 19 9.46717C19 4.29076 14.7384 0.0795898 9.5 0.0795898L9.5 0.0797267ZM10.6696 10.0186C10.7054 10.5187 10.4549 10.9873 10.0156 11.2418C9.80975 11.3611 9.58129 11.4179 9.35473 11.4179C8.91788 11.4179 8.48885 11.2066 8.24232 10.8249C7.97637 10.413 7.96679 9.89365 8.21729 9.469C8.21784 9.4679 8.21852 9.46681 8.21907 9.46571L10.1061 6.35059C10.1758 6.22636 10.3218 6.16685 10.4585 6.20598C10.5953 6.24566 10.6868 6.37426 10.6794 6.51626L10.6061 7.934C10.57 8.63117 10.6208 9.33656 10.6696 10.0186ZM16.2652 8.83598C16.2069 8.90219 16.1232 8.94023 16.0351 8.94023L14.0974 8.94037C13.9496 8.94037 13.8228 8.83489 13.796 8.68959C13.6437 7.86438 13.2504 7.1048 12.6587 6.49298C11.8259 5.63163 10.7041 5.15745 9.4999 5.15745C8.28478 5.15745 7.15609 5.63888 6.32156 6.51309C6.32142 6.51322 6.32142 6.51322 6.32129 6.51336C5.74082 7.12146 5.35448 7.87408 5.20384 8.68959C5.17703 8.83488 5.05034 8.94036 4.90245 8.94036H2.9647C2.87659 8.94036 2.79259 8.90233 2.73459 8.83611C2.67658 8.76989 2.64949 8.68179 2.66084 8.59451C2.84882 7.14161 3.49359 5.8121 4.5254 4.74968C5.83849 3.39747 7.60526 2.65267 9.50007 2.65267C11.3824 2.65267 13.1414 3.38952 14.4527 4.72751C15.4975 5.79364 16.1499 7.13082 16.3394 8.5944C16.3506 8.68182 16.3237 8.76993 16.2655 8.836L16.2652 8.83598Z"
        fill="#63719C"
      />
      <path
        d="M3.32324 8.32767H4.65426C4.8317 7.58781 5.18069 6.90048 5.67662 6.31427L4.75495 5.40381C4.01617 6.24191 3.52669 7.24182 3.32324 8.32767Z"
        fill="#63719C"
      />
      <path
        d="M13.7956 4.94365C12.7014 3.92813 11.3045 3.34314 9.80615 3.27295V4.55374C10.958 4.62215 12.0297 5.07471 12.8771 5.85096L13.7956 4.94365Z"
        fill="#63719C"
      />
      <path
        d="M13.3037 6.29135C13.8102 6.8825 14.1659 7.57789 14.3456 8.32763H15.6765C15.4713 7.23179 14.975 6.22407 14.2247 5.38159L13.3037 6.29135Z"
        fill="#63719C"
      />
    </svg>
  );
}

export default DashboardIcon;
