import React from "react";

function TechProcurementIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M289 5096 c-136 -36 -236 -150 -259 -292 -13 -80 -13 -4408 0 -4488
19 -120 90 -215 198 -268 l67 -33 2265 0 2265 0 67 33 c108 53 179 148 198
268 13 80 13 4408 0 4488 -19 120 -90 215 -198 268 l-67 33 -2245 2 c-1758 1
-2255 -1 -2291 -11z m4512 -148 c53 -18 87 -45 116 -96 28 -47 28 -49 31 -254
l4 -208 -2392 0 -2392 0 4 208 c3 191 5 210 26 249 24 45 69 84 117 101 45 16
4440 16 4486 0z m147 -2670 l-3 -1963 -28 -47 c-29 -51 -63 -78 -116 -96 -49
-17 -4433 -17 -4482 0 -53 18 -87 45 -116 96 l-28 47 -3 1963 -2 1962 2390 0
2390 0 -2 -1962z"
        />
        <path
          d="M549 4731 c-17 -13 -23 -29 -23 -56 0 -62 26 -75 149 -75 123 0 149
13 149 75 0 62 -26 75 -149 75 -86 0 -107 -3 -126 -19z"
        />
        <path
          d="M1069 4731 c-17 -13 -23 -29 -23 -56 0 -62 26 -75 149 -75 123 0 149
13 149 75 0 62 -26 75 -149 75 -86 0 -107 -3 -126 -19z"
        />
        <path
          d="M1589 4731 c-17 -13 -23 -29 -23 -56 0 -62 26 -75 149 -75 123 0 149
13 149 75 0 62 -26 75 -149 75 -86 0 -107 -3 -126 -19z"
        />
        <path
          d="M2696 4729 c-36 -28 -36 -80 0 -108 27 -21 31 -21 920 -21 491 0 900
3 909 6 45 18 48 111 5 134 -12 6 -349 10 -913 10 -890 0 -894 0 -921 -21z"
        />
        <path
          d="M554 3422 c-89 -32 -154 -100 -180 -191 -43 -145 49 -311 193 -351
58 -17 158 -8 209 18 51 26 118 98 135 145 12 35 15 37 59 37 l45 0 130 -191
c71 -104 136 -195 144 -202 8 -7 54 -14 103 -16 l88 -3 -2 -197 -3 -196 -272
-3 -273 -2 -25 50 c-54 106 -141 162 -255 163 -214 2 -353 -220 -257 -409 109
-212 415 -209 512 5 l18 41 279 0 278 0 0 -194 0 -194 -88 -4 c-49 -1 -95 -8
-103 -15 -8 -7 -73 -98 -144 -202 l-130 -191 -45 0 c-44 0 -47 2 -59 37 -17
47 -84 119 -135 145 -51 26 -151 35 -209 18 -69 -19 -137 -76 -172 -145 -74
-144 -13 -320 135 -389 69 -32 179 -29 246 6 59 31 118 95 134 144 l12 34 74
0 c42 0 83 4 93 10 10 5 78 98 152 205 l133 196 50 -3 51 -3 5 -100 c6 -111
18 -143 76 -199 71 -69 30 -66 1011 -66 641 0 897 3 931 12 33 8 60 26 98 62
61 60 74 96 74 214 l0 82 53 0 53 0 129 -191 c72 -105 139 -198 150 -205 12
-9 47 -14 95 -14 l76 0 12 -33 c18 -51 79 -115 134 -143 143 -72 310 -16 385
130 22 41 27 65 27 121 -1 118 -53 203 -156 254 -117 59 -246 35 -334 -61 -23
-25 -48 -61 -55 -81 -12 -35 -15 -37 -59 -37 l-47 0 -132 196 c-88 129 -141
198 -158 205 -13 5 -58 9 -99 9 l-74 0 0 195 0 195 279 0 279 0 11 -31 c19
-54 78 -116 137 -145 141 -69 310 -10 381 133 92 189 -46 406 -259 406 -116 0
-217 -68 -262 -176 l-15 -37 -273 2 -273 3 -3 198 -2 197 74 0 c41 0 86 4 99
9 17 7 70 76 158 205 l133 196 46 0 c44 0 47 -2 59 -37 19 -52 88 -122 146
-149 141 -66 318 4 382 153 27 62 22 167 -10 229 -75 146 -242 202 -385 130
-55 -28 -116 -92 -134 -143 l-12 -33 -76 0 c-48 0 -83 -5 -95 -14 -11 -7 -78
-100 -150 -205 l-129 -191 -53 0 -53 0 0 83 c0 117 -13 153 -74 213 -38 36
-65 54 -98 62 -34 9 -290 12 -931 12 -981 0 -940 3 -1011 -66 -58 -56 -70 -88
-76 -199 l-5 -100 -51 -3 -50 -3 -133 196 c-74 107 -142 200 -152 205 -10 6
-51 10 -93 10 l-74 0 -12 34 c-42 128 -220 207 -356 158z m153 -149 c126 -60
78 -253 -63 -253 -136 0 -180 178 -61 250 39 24 78 25 124 3z m3847 3 c73 -30
102 -107 67 -179 -28 -58 -71 -82 -137 -74 -39 4 -55 12 -81 41 -28 30 -33 43
-33 83 0 106 89 169 184 129z m-2314 -421 c0 -177 1 -185 22 -205 12 -11 32
-20 44 -20 12 0 75 23 140 50 65 28 123 50 129 50 6 0 67 -22 135 -50 132 -54
155 -58 186 -36 17 13 19 31 24 203 l5 188 268 3 268 2 30 -29 29 -29 0 -782
0 -782 -29 -29 -29 -29 -885 0 -885 0 -31 26 -31 26 0 788 0 788 31 26 31 26
274 0 274 0 0 -185z m530 74 c0 -85 -3 -110 -12 -106 -7 3 -51 20 -97 38 l-84
33 -56 -21 c-31 -11 -73 -28 -93 -37 l-38 -16 0 110 0 110 190 0 190 0 0 -111z
m-2073 -604 c12 -3 36 -22 52 -41 26 -29 31 -43 31 -84 0 -114 -124 -173 -217
-103 -86 63 -55 209 49 233 33 8 44 7 85 -5z m3874 -11 c60 -30 83 -128 44
-187 -52 -80 -180 -78 -229 3 -21 37 -21 109 1 140 45 65 111 81 184 44z
m-3860 -949 c93 -48 90 -194 -4 -238 -46 -22 -85 -21 -124 3 -63 38 -88 111
-58 173 32 69 118 97 186 62z m3873 -15 c19 -17 40 -47 47 -67 33 -102 -76
-201 -179 -161 -80 30 -110 143 -55 208 32 38 55 49 110 49 33 1 50 -5 77 -29z"
        />
        <path
          d="M2787 1926 c-36 -33 -39 -53 -35 -216 3 -140 3 -142 30 -165 38 -32
82 -37 308 -33 263 4 262 4 268 197 5 140 -5 190 -45 220 -24 20 -39 21 -263
21 -237 0 -237 0 -263 -24z m421 -193 l-3 -68 -152 -3 -153 -3 0 71 0 70 155
0 156 0 -3 -67z"
        />
      </g>
    </svg>
  );
}

export default TechProcurementIcon;
