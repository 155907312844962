import React from "react";

function ProjectsIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 20.000000 20.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,20.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M4 176 c-3 -7 -4 -42 -2 -77 l3 -64 98 -3 97 -3 0 71 0 70 -74 0
   c-41 0 -78 5 -81 10 -9 15 -35 12 -41 -4z m36 -49 c0 -41 -4 -54 -15 -54 -11
   0 -15 13 -15 54 0 40 4 53 15 53 11 0 15 -13 15 -53z m150 -27 l0 -60 -90 0
   c-50 0 -90 4 -90 8 0 17 13 22 27 11 11 -9 13 -1 13 45 l0 56 70 0 70 0 0 -60z"
        />
        <path
          d="M144 123 c-9 -15 -14 -16 -26 -5 -13 10 -18 9 -29 -5 -12 -16 -12
   -17 2 -6 11 9 19 9 26 2 11 -11 53 8 53 23 0 14 -15 8 -26 -9z"
        />
        <path d="M93 63 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z" />
      </g>
    </svg>
  );
}

export default ProjectsIcon;
