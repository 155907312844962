// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background-color: #391f5b;
  z-index: 1;
  transition: all ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/CustomComponents/ScrollProgress/progressBar.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,yBAAA;EACA,UAAA;EACA,2BAAA;AADF","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.progressBar {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 4px;\n  background-color: @primary-color;\n  z-index: 1;\n  transition: all ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
