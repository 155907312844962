import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { fetchAPI } from "services/api.Service";

// FETCH ATTACHMENT
export const useFetchAttachment = (userParams) => {
  return useQuery({
    queryKey: ["Attachment", userParams],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.ATTACHMENT.GET_ALL_ATTACHMENTS,
        queryParams: userParams,
        data: {},
        signal,
      }),
    placeholderData: keepPreviousData,
  });
};

// FETCH ATTACHMENT BY ID
export const useFetchAttachmentById = (id) => {
  return useQuery({
    queryKey: ["Attachment", id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.ATTACHMENT.GET_ALL_ATTACHMENTS}/${id}`,
        queryParams: {},
      }),
    enabled: false,
  });
};

// ADD ATTACHMENT
export const useAddAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.ATTACHMENT.GET_ALL_ATTACHMENTS,
        queryParams: {},
        data: data,
        headers: {
          "Content-Type": "Multipart/formData",
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Attachment"]);
    },
  });
};

// UPDATE ATTACHMENT
export const useUpdateAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.ATTACHMENT.GET_ALL_ATTACHMENTS}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Attachment"]);
    },
  });
};

// DELETE ATTACHMENT
export const useDeleteAttachment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.ATTACHMENT.GET_ALL_ATTACHMENTS}/${id}`,
        queryParams: {},
        // data: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Attachment"]);
    },
  });
};
