import React, { useState } from "react";
import { Popconfirm } from "antd";
import CustomButton from "../CustomButton";

export const CustomPopConfirm = ({ title, buttonChildren, onConfirm }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => setOpen(true);
  const handleOk = async () => {
    setConfirmLoading(true);
    if (onConfirm) {
      await onConfirm();
    }

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title={title}
      open={open}
      onConfirm={handleOk}
      okButtonProps={{
        loading: confirmLoading,
      }}
      onCancel={handleCancel}
    >
      <CustomButton
        htmlType="default"
        className="action-btn trash-icon textWhite"
        onClick={showPopconfirm}
      >
        {buttonChildren}
      </CustomButton>
    </Popconfirm>
  );
};
