// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customStatusBadge .ant-badge sup {
  height: 28px;
  min-width: 75px;
  border-radius: 0;
  padding: 4px;
  line-height: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomStatusBadge/CustomStatusBadge.less"],"names":[],"mappings":"AAEA;EAGM,YAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;AAHN","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.customStatusBadge {\n  .ant-badge {\n    sup {\n      height: 28px;\n      min-width: 75px;\n      border-radius: 0;\n      padding: 4px;\n      line-height: 1;\n      display: flex !important;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
