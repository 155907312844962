import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;

// Axios instance
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Automatically attach JWT token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("jwtToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchAPI = (fetchParams) => {
  const { method, url, queryParams, data, signal } = fetchParams;
  return axiosInstance({
    method: method,
    url: url,
    // headers: headers,
    data: data,
    params: queryParams,
    signal,
  });
};
