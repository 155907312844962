import React, { memo } from "react";
import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./CustomTable.less";

const CustomTable = ({
  data,
  className = "",
  pagination,
  loading,
  listingCallback = () => {},
  ...props
}) => {
  const pageSizeOptions = ["10", "20", "50", "100"];

  let uniqueId = 0;

  const handleOnChange = (newPagination, filters, sorter) => {
    const { current, pageSize } = newPagination;
    listingCallback({ pageNo: current, pageSize, filters, sorter });
  };

  const currentPage = pagination.currentPage > 0 ? pagination.currentPage : 1;

  return (
    <div className="custom-table-grid-view-container">
      <Table
        dataSource={data}
        className={`${className} custom-table-container cardWithStyle`}
        pagination={{
          current: currentPage,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: false,
          pageSizeOptions,
          onChange: handleOnChange,
          onShowSizeChange: handleOnChange,
        }}
        onChange={handleOnChange}
        rowKey={(record) => record?.id || `unique-${++uniqueId}`}
        loading={{
          spinning: loading ? loading : false,
          indicator: (
            <LoadingOutlined
              style={{
                fontSize: 40,
                color: "#391f5b",
              }}
              spin
            />
          ), // Custom loading indicator
        }}
        {...props}
      />
    </div>
  );
};

export default memo(CustomTable);
