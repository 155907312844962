import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { fetchAPI } from "services/api.Service";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";

// FETCH CONTRACT
export const useFetchContract = (contractParams) => {
  return useQuery({
    queryKey: ["Contracts", contractParams],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.CONTRACT.GET_ALL_CONTRACTS,
        queryParams: contractParams,
        data: {},
        signal,
      }),
    placeholderData: keepPreviousData,
  });
};

// FETCH CONTRACT BY ID
export const useFetchContractById = (id) => {
  return useQuery({
    queryKey: ["Contracts", id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.CONTRACT.GET_ALL_CONTRACTS}/${id}`,
        queryParams: {},
      }),
    enabled: false,
  });
};

// ADD CONTRACT
export const useAddContract = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.CONTRACT.GET_ALL_CONTRACTS,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Contracts"]);
    },
  });
};

// UPDATE CONTRACT
export const useUpdateContract = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.CONTRACT.GET_ALL_CONTRACTS}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Contracts"]);
    },
  });
};

// DELETE CONTRACT
export const useDeleteContract = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.CONTRACT.GET_ALL_CONTRACTS}/${id}`,
        queryParams: {},
        data: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Contracts"]);
    },
  });
};

export const useContractAccept = (id) => {
  return useQuery({
    queryKey: ["Contracts", id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.CONTRACT.ACCEPT}/${id}`,
        queryParams: {},
      }),
    enabled: false,
  });
};
