// CONTROLLERS
const authController = "/auth";
const adminRoute = "/admin";

// ROUTES
const loginRoute = "/login";
const logoutRoute = "/logout";
const signupRoute = "/signup";
const forgotRoute = "/forgot-password";
const otpRoute = "/verify-otp";
const refreshTokenRoute = "/refresh";
const resetPwdRoute = "/reset-password";
const meRoute = "/me";
const verifyEmailRoute = "/verify-email/";
const changePasswordRoute = "/change-password";
const contractRoute = "/contract";
const contractAccept = "/contract/accept";
const planRoute = "/plan";
const projectRoute = "/project";
const reportRoute = "/report";
const attachmentRoute = "/attachment";
const invoiceRoute = "/invoice";
const serviceRoute = "/service";
const permissionRoute = "/permissions";
const roleRoute = "/roles";
const userRoute = "/user";
const customerRoute = "/customer";
const techProcurementsRoute = "/tech-procurements";
const webServiceRoute = "/web-service";
const activityRoute = "/activity";
const postRoute = "/posts";

export const SERVICE_URL = {
  AUTH: {
    LOGIN: `${authController}${loginRoute}`,
    LOGOUT: `${authController}${logoutRoute}`,
    SIGN_UP: `${authController}${adminRoute}${signupRoute}`,
    FORGOT: `${authController}${forgotRoute}`,
    OTP: `${authController}${otpRoute}`,
    REFRESH_TOKEN: `${authController}${refreshTokenRoute}`,
    RESET_PASSWORD: `${authController}${resetPwdRoute}`,
    CHANGE_PASSWORD: `${authController}${changePasswordRoute}`,
    VERIFY_EMAIL: `${authController}${verifyEmailRoute}{id}`,
    ME: `${authController}${meRoute}`,
  },
  // MEDIA: {
  //   SINGLE_UPLOAD: `${mediaController}/file`,
  //   MULTIPLE_UPLOAD: `${mediaController}/files`,
  // },
  CONTRACT: {
    GET_ALL_CONTRACTS: `${contractRoute}`,
    ACCEPT: `${contractAccept}`,
  },
  PLAN: {
    GET_ALL_PLANS: `${planRoute}`,
  },
  PROJECT: {
    GET_ALL_PROJECTS: `${projectRoute}`,
  },
  REPORT: {
    GET_ALL_REPORTS: `${reportRoute}`,
  },
  ATTACHMENT: {
    GET_ALL_ATTACHMENTS: `${attachmentRoute}`,
  },
  INVOICE: {
    GET_ALL_INVOICES: `${invoiceRoute}`,
  },
  SERVICE: {
    GET_ALL_SERVICES: `${serviceRoute}`,
  },
  PERMISSION: {
    GET_ALL_PERMISSION: `${permissionRoute}`,
  },
  ROLE: {
    GET_ALL_ROLE: `${roleRoute}`,
  },
  USER: {
    GET_ALL_USERS: `${userRoute}`,
  },
  CUSTOMER: {
    GET_ALL_CUSTOMERS: `${customerRoute}`,
  },
  TECHPROCUREMENTS: {
    GET_ALL_TECH_PROCUREMENTS: `${techProcurementsRoute}`,
  },
  WEBSERVICE: {
    GET_ALL_WEB_SERVICE: `${webServiceRoute}`,
  },
  ACTIVITY: {
    GET_ALL_ACTIVITY: `${activityRoute}`,
  },
  POSTS: {
    GET_ALL_POSTS: `${postRoute}`,
  },
  LOCATION: {
    GET_LOCATION_FROM_COORDINATES: `https://maps.googleapis.com/maps/api/geocode/json`,
  },
  ADMIN: {
    CHANGE_ADMIN_PASSWORD: `${authController}${changePasswordRoute}`,
  },
};
