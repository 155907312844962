import React from "react";

function WebServiceIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M249 4686 c-98 -26 -181 -95 -223 -185 l-21 -46 0 -1895 0 -1895 28
-57 c33 -67 90 -121 166 -157 l56 -26 2279 -3 c2479 -2 2344 -5 2434 52 50 31
100 89 126 145 l21 46 0 1895 0 1895 -21 46 c-26 56 -76 114 -126 145 -90 57
44 54 -2413 53 -1758 0 -2270 -3 -2306 -13z m4041 -400 l0 -265 -174 -3 c-200
-3 -216 -9 -216 -79 0 -71 -19 -69 557 -69 l513 0 0 -1577 c0 -1510 -1 -1580
-19 -1618 -10 -22 -28 -48 -39 -58 -53 -48 19 -46 -1939 -47 l-1843 0 -2 879
c-3 848 -4 880 -22 900 -26 29 -86 29 -112 0 -18 -20 -19 -52 -22 -900 l-2
-879 -333 0 c-347 1 -382 5 -429 47 -11 10 -29 36 -39 58 -18 38 -19 108 -19
1618 l0 1577 410 0 409 0 3 -624 c3 -597 4 -625 22 -645 26 -29 86 -29 112 0
18 20 19 48 22 645 l3 624 1257 0 c1404 0 1302 -5 1302 69 0 26 -7 44 -21 57
-21 19 -66 19 -1770 22 l-1749 2 0 193 c0 206 9 247 58 290 53 48 -30 46 2090
47 l1992 0 0 -264z m563 250 c50 -18 76 -42 98 -91 16 -34 19 -67 19 -232 l0
-193 -265 0 -265 0 0 265 0 265 188 0 c131 -1 198 -5 225 -14z"
        />
        <path
          d="M339 4331 c-34 -35 -36 -59 -8 -95 16 -21 29 -26 64 -26 35 0 48 5
64 26 34 43 26 86 -20 109 -42 22 -67 18 -100 -14z"
        />
        <path
          d="M685 4351 c-49 -21 -64 -83 -30 -121 26 -29 97 -28 125 2 55 60 -18
152 -95 119z"
        />
        <path
          d="M1010 4353 c-34 -13 -50 -37 -50 -74 0 -46 27 -69 82 -69 68 0 103
68 61 119 -19 22 -67 34 -93 24z"
        />
        <path
          d="M3598 3590 c-76 -23 -128 -98 -128 -187 0 -68 -20 -76 -69 -29 -40
39 -97 66 -138 66 -49 -1 -106 -36 -181 -113 l-74 -74 -71 24 c-184 62 -438
70 -632 19 -446 -117 -781 -511 -825 -972 -36 -385 133 -765 444 -996 102 -76
254 -150 381 -185 93 -26 113 -28 285 -27 168 0 194 3 282 26 191 52 360 151
503 293 83 82 181 219 224 313 l24 52 96 0 c114 0 154 10 194 47 42 41 57 75
57 136 0 79 19 91 68 44 66 -64 137 -82 210 -52 45 19 167 137 193 187 36 70
22 148 -38 209 -46 48 -42 73 12 77 103 6 163 40 191 109 19 44 19 242 0 286
-28 69 -88 103 -191 109 -54 4 -58 29 -12 77 42 42 57 77 57 132 0 56 -28 104
-107 181 -122 120 -206 129 -312 33 -53 -48 -71 -37 -71 42 0 82 -36 136 -112
168 -41 16 -211 20 -260 5z m224 -207 c8 -72 26 -111 68 -147 74 -61 156 -56
242 15 26 21 49 39 53 39 15 0 125 -114 125 -130 0 -9 -13 -29 -30 -45 -40
-38 -60 -84 -60 -140 0 -56 17 -92 64 -133 35 -31 63 -39 141 -44 l40 -3 0
-95 0 -95 -60 -6 c-75 -8 -103 -19 -136 -55 -39 -42 -49 -67 -49 -119 0 -56
20 -102 60 -140 17 -16 30 -36 30 -44 0 -18 -109 -131 -127 -131 -6 0 -32 18
-58 40 -79 68 -164 73 -235 14 -42 -36 -60 -75 -68 -147 l-7 -62 -73 -3 c-69
-3 -73 -2 -67 17 15 48 25 145 25 247 l0 111 59 6 c121 11 230 86 292 199 33
60 34 68 34 168 0 100 -1 108 -34 168 -113 209 -378 266 -559 120 l-53 -43
-72 70 c-40 39 -105 92 -145 119 l-72 50 52 53 c29 29 56 53 62 53 5 0 28 -18
51 -39 89 -82 203 -75 271 15 22 28 29 50 32 97 2 34 9 68 15 75 7 9 35 12 96
10 l86 -3 7 -62z m-1141 -250 c60 -56 126 -184 164 -317 40 -141 69 -126 -253
-126 -153 0 -281 3 -284 6 -4 3 5 45 18 93 67 246 166 391 267 391 31 0 46 -8
88 -47z m-411 -64 c-36 -70 -92 -235 -110 -321 l-11 -58 -200 0 -200 0 16 29
c52 101 204 252 329 327 60 36 197 96 204 89 2 -1 -11 -31 -28 -66z m729 20
c105 -50 197 -115 279 -197 62 -63 142 -171 142 -191 0 -7 -65 -11 -195 -11
l-195 0 -6 28 c-34 151 -82 293 -125 370 -30 53 -30 54 -7 47 13 -4 61 -24
107 -46z m792 -180 c88 -27 149 -113 149 -210 -1 -125 -94 -219 -218 -219
l-49 0 -18 66 c-10 37 -41 113 -70 170 l-52 103 36 35 c63 62 137 80 222 55z
m-1676 -689 l0 -320 -220 2 -220 3 -23 90 c-39 150 -30 393 18 521 l10 24 217
0 218 0 0 -320z m789 298 c3 -13 7 -47 10 -77 7 -75 27 -101 75 -101 64 0 82
34 72 133 l-7 67 223 0 222 0 16 -52 c26 -93 35 -158 35 -268 0 -110 -9 -175
-35 -267 l-16 -53 -222 0 -223 0 9 86 c8 78 7 89 -11 113 -13 18 -30 27 -55
29 -56 5 -74 -20 -82 -115 -4 -43 -9 -86 -11 -95 -5 -17 -29 -18 -319 -18
l-313 0 -7 107 c-9 122 -2 504 9 521 5 9 91 12 316 12 l310 0 4 -22z m-759
-778 c4 -6 15 -46 25 -88 24 -99 70 -227 108 -297 17 -30 28 -55 26 -55 -2 0
-33 11 -69 25 -117 44 -257 138 -346 232 -50 52 -129 162 -129 179 0 11 34 14
189 14 121 0 192 -4 196 -10z m725 -9 c0 -40 -53 -200 -95 -286 -56 -116 -124
-185 -182 -185 -76 0 -152 82 -214 233 -35 82 -80 243 -71 251 3 3 131 6 284
6 266 0 278 -1 278 -19z m550 8 c0 -21 -86 -135 -150 -199 -36 -37 -94 -85
-128 -109 -61 -41 -202 -112 -251 -126 l-23 -7 25 44 c47 80 93 214 131 381
l6 27 195 0 c130 0 195 -4 195 -11z"
        />
        <path
          d="M331 3524 c-27 -35 -26 -57 3 -91 l24 -28 172 -3 c193 -4 232 0 261
29 25 25 24 74 -1 99 -19 19 -33 20 -229 20 l-210 0 -20 -26z"
        />
        <path
          d="M339 3191 c-17 -13 -23 -29 -23 -56 0 -27 6 -43 23 -56 21 -17 43
-19 221 -19 184 0 199 1 224 21 36 28 36 80 0 108 -25 20 -40 21 -224 21 -178
0 -200 -2 -221 -19z"
        />
        <path
          d="M364 2860 c-26 -10 -54 -48 -54 -71 0 -9 9 -28 21 -43 l20 -26 210 0
c196 0 210 1 229 20 25 25 26 71 1 101 -19 24 -23 24 -213 26 -106 1 -203 -2
-214 -7z"
        />
        <path
          d="M331 2284 c-27 -35 -26 -57 3 -91 l24 -28 172 -3 c193 -4 232 0 261
29 25 25 24 74 -1 99 -19 19 -33 20 -229 20 l-210 0 -20 -26z"
        />
        <path
          d="M339 1951 c-17 -13 -23 -29 -23 -56 0 -27 6 -43 23 -56 21 -17 43
-19 221 -19 184 0 199 1 224 21 36 28 36 80 0 108 -25 20 -40 21 -224 21 -178
0 -200 -2 -221 -19z"
        />
        <path
          d="M364 1620 c-26 -10 -54 -48 -54 -71 0 -9 9 -28 21 -43 l20 -26 210 0
c196 0 210 1 229 20 25 25 26 71 1 101 -19 24 -23 24 -213 26 -106 1 -203 -2
-214 -7z"
        />
        <path
          d="M4109 1550 c-44 -13 -74 -38 -100 -82 -23 -42 -24 -48 -24 -263 0
-121 3 -232 7 -247 10 -35 53 -85 92 -106 27 -15 63 -17 261 -17 222 0 231 1
270 23 22 13 52 39 65 59 24 36 25 41 28 257 4 232 -1 272 -40 321 -45 57 -81
65 -318 64 -118 0 -227 -4 -241 -9z m439 -152 c9 -9 12 -68 12 -205 0 -180 -1
-193 -19 -203 -12 -6 -95 -10 -200 -10 -150 0 -182 3 -195 16 -13 13 -16 45
-16 195 0 105 4 188 10 200 10 18 23 19 203 19 137 0 196 -3 205 -12z"
        />
        <path
          d="M331 1044 c-27 -35 -26 -57 3 -91 l24 -28 172 -3 c193 -4 232 0 261
29 25 25 24 74 -1 99 -19 19 -33 20 -229 20 l-210 0 -20 -26z"
        />
      </g>
    </svg>
  );
}

export default WebServiceIcon;
