import React from "react";

function EditIcon() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3252 1.41667H6.90853C3.36686 1.41667 1.9502 2.83333 1.9502 6.375V10.625C1.9502 14.1667 3.36686 15.5833 6.90853 15.5833H11.1585C14.7002 15.5833 16.1169 14.1667 16.1169 10.625V9.20834"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.895 2.13917L6.31336 7.72083C6.10086 7.93333 5.88836 8.35125 5.84586 8.65583L5.54128 10.7879C5.42794 11.56 5.97336 12.0983 6.74545 11.9921L8.87753 11.6875C9.17503 11.645 9.59294 11.4325 9.81253 11.22L15.3942 5.63833C16.3575 4.675 16.8109 3.55583 15.3942 2.13917C13.9775 0.7225 12.8584 1.17583 11.895 2.13917Z"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0947 2.93958C11.3296 3.77362 11.7747 4.53338 12.3874 5.14607C13.0001 5.75877 13.7599 6.20387 14.5939 6.43875"
        stroke="black"
        strokeWidth="0.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
