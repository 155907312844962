import React from "react";

function InvoiceIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M140 210 c0 -5 -25 -10 -55 -10 l-55 0 0 -70 c0 -56 -3 -70 -15 -70
   -16 0 -21 -35 -6 -44 5 -4 7 3 4 14 -5 18 0 20 51 20 42 0 56 -4 56 -14 0 -8
   4 -17 9 -20 4 -3 6 6 3 19 -4 22 -9 25 -48 25 l-44 0 0 65 0 65 45 0 c32 0 45
   -4 45 -14 0 -8 -8 -17 -17 -19 -15 -4 -15 -5 2 -6 43 -3 57 -26 53 -84 -1 -30
   0 -53 5 -50 4 2 7 26 7 54 0 27 4 49 8 49 5 0 17 7 28 15 18 14 17 15 -7 4
   -43 -18 -73 4 -66 47 3 19 4 34 1 34 -2 0 -4 -4 -4 -10z"
        />
        <path d="M226 195 c8 -37 14 -45 14 -19 0 10 -5 26 -10 34 -8 11 -9 7 -4 -15z" />
        <path
          d="M177 203 c-8 -14 -2 -53 8 -56 5 -1 11 6 13 16 2 9 -1 17 -7 17 -6 0
   -11 5 -11 11 0 5 5 7 12 3 7 -4 8 -3 4 4 -8 14 -13 15 -19 5z"
        />
        <path
          d="M60 150 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
   -10 -4 -10 -10z"
        />
        <path
          d="M60 120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
   -10 -4 -10 -10z"
        />
        <path d="M65 91 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
        <path d="M113 83 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
      </g>
    </svg>
  );
}

export default InvoiceIcon;
