import React from "react";
import { AutoComplete, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./CustomSearchBar.less";

function CustomSearchBar({ props, onSearch }) {
  const handleChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <div className="searchbarWrapper">
      <AutoComplete>
        <Input.Search
          size="large"
          placeholder="Search..."
          prefix={<SearchOutlined />}
          allowClear
          onChange={handleChange}
          {...props}
        />
      </AutoComplete>
    </div>
  );
}

export default CustomSearchBar;
