import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "layout";
import {
  authenticatedRoutes,
  unAuthenticatedRoutes,
} from "utils/Constants/routes.constant";
import { useAuth } from "hooks/useAuth";

import FullScreenLoader from "components/CustomComponents/FullScreenLoader";
import Report from "screens/Report";
import AddEditReport from "screens/Report/AddEdit";
import ReportActivityDetail from "screens/Report/ActivityDetail";
import ProtectedRoute from "./ProtectedRoute";

const Dashboard = lazy(() => import("screens/Dashboard"));
const Users = lazy(() => import("screens/Users"));
const UserActivityDetail = lazy(() => import("screens/Users/ActivityDetail"));
const AddEditUsers = lazy(() => import("screens/Users/AddEdit"));
const Customers = lazy(() => import("screens/Customers"));
const CustomerActivityDetail = lazy(() =>
  import("screens/Customers/ActivityDetail")
);
const Contracts = lazy(() => import("screens/Contracts"));
const ContractActivityDetail = lazy(() =>
  import("screens/Contracts/ActivityDetail")
);
const AddEditCustomers = lazy(() => import("screens/Customers/AddEdit"));
const AddEditContract = lazy(() => import("screens/Contracts/AddEdit"));
const Plans = lazy(() => import("screens/Plans"));
const PlanActivityDetail = lazy(() => import("screens/Plans/ActivityDetail"));
const AddEditPlan = lazy(() => import("screens/Plans/AddEdit"));
const Projects = lazy(() => import("screens/Projects"));
const ProjectActivityDetail = lazy(() =>
  import("screens/Projects/ActivityDetail")
);
const AddEditProject = lazy(() => import("screens/Projects/AddEdit"));
const Invoices = lazy(() => import("screens/Invoices"));
const InvoiceActivityDetail = lazy(() =>
  import("screens/Invoices/ActivityDetail")
);
const AddEditInvoices = lazy(() => import("screens/Invoices/AddEdit"));
const DetailInvoices = lazy(() => import("screens/Invoices/Detail"));
const Services = lazy(() => import("screens/Services"));
const ServiceActivityDetail = lazy(() =>
  import("screens/Services/ActivityDetail")
);
const AccessControl = lazy(() => import("screens/AccessControl"));
const RoleActivityDetail = lazy(() =>
  import("screens/AccessControl/RoleActivityDetail")
);
const PermissionActivityDetail = lazy(() =>
  import("screens/AccessControl/PermissionActivityDetail")
);
const AddEditRole = lazy(() => import("screens/AccessControl/AddEdit"));
const DetailContract = lazy(() => import("screens/Contracts/Detail"));
const AddEditService = lazy(() => import("screens/Services/AddEdit"));
const TechProcurements = lazy(() => import("screens/TechProcurements"));
const AddEditTechProcurements = lazy(() =>
  import("screens/TechProcurements/AddEdit")
);
const TechProcurementActivityDetail = lazy(() =>
  import("screens/TechProcurements/ActivityDetail")
);

const WebService = lazy(() => import("screens/WebService"));
const AddEditWebService = lazy(() => import("screens/WebService/AddEdit"));
const WebActivityDetail = lazy(() =>
  import("screens/WebService/ActivityDetail")
);
const Activity = lazy(() => import("screens/Activity"));
// const DetailActivity = lazy(() => import("screens/Activity/Detail"));

const Login = lazy(() => import("screens/Auth/Login"));
const ForgotPassword = lazy(() => import("screens/Auth/ForgotPassword"));
const CheckEmail = lazy(() => import("screens/Auth/CheckEmail"));
const ResetPassword = lazy(() => import("screens/Auth/ResetPassword"));
const ChangePassword = lazy(() => import("screens/Auth/ChangePassword"));
const NotFound = lazy(() => import("screens/NotFound"));
const UnAuthorized = lazy(() => import("screens/UnAuthorized"));

const PublicRoute = ({ children }) => {
  const { accessToken } = useAuth();
  if (accessToken)
    return <Navigate to={`${authenticatedRoutes.DASHBOARD}`} replace />;
  return children;
};

function AppRoutes() {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        {/* UNAUTHENTICATED ROUTES */}
        <Route>
          <Route
            path={`${unAuthenticatedRoutes.LOGIN}`}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={`${unAuthenticatedRoutes.FORGOT_PASSWORD}`}
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path={`${unAuthenticatedRoutes.CHECK_EMAIL}`}
            element={
              <PublicRoute>
                <CheckEmail />
              </PublicRoute>
            }
          />
          <Route
            path={`${unAuthenticatedRoutes.RESET_PASSWORD}`}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
        </Route>

        {/* AUTHENTICATED ROUTES */}
        <Route path="/" element={<MainLayout />}>
          {/* DASHBOARD  */}
          <Route
            path={`${authenticatedRoutes.DASHBOARD}`}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          {/* CHANGE PASSWORD  */}
          <Route
            path={`${authenticatedRoutes.CHANGE_PASSWORD}`}
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />

          {/* USERS  */}
          <Route path={`${authenticatedRoutes.USER}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_user"]}>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_user"]}>
                  <AddEditUsers />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_user"]}>
                    <AddEditUsers />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_user"]}>
                    <UserActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* CUSTOMERS  */}
          <Route path={`${authenticatedRoutes.CUSTOMERS}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_customer"]}>
                  <Customers />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_customer"]}>
                  <AddEditCustomers />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_customer"]}>
                    <AddEditCustomers />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_customer"]}>
                    <CustomerActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* CONTRACTS  */}
          <Route path={`${authenticatedRoutes.CONTRACTS}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_contract"]}>
                  <Contracts />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_contract"]}>
                  <AddEditContract />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_contract"]}>
                    <AddEditContract />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={`${authenticatedRoutes.DETAIL}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_contract"]}>
                    <DetailContract />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_contract"]}>
                    <ContractActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* PLANS  */}
          <Route path={`${authenticatedRoutes.PLAN}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_plan"]}>
                  <Plans />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_plan"]}>
                  <AddEditPlan />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_plan"]}>
                    <AddEditPlan />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_plan"]}>
                    <PlanActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* PROJECTS  */}
          <Route path={`${authenticatedRoutes.PROJECT}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_project"]}>
                  <Projects />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_project"]}>
                  <AddEditProject />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_project"]}>
                    <AddEditProject />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_project"]}>
                    <ProjectActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* REPORTS  */}
          <Route path={`${authenticatedRoutes.REPORT}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_report"]}>
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_report"]}>
                  <AddEditReport />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_report"]}>
                    <AddEditReport />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_report"]}>
                    <ReportActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* INVOICES  */}
          <Route path={`${authenticatedRoutes.INVOICE}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_invoice"]}>
                  <Invoices />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_invoice"]}>
                  <AddEditInvoices />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_invoice"]}>
                    <AddEditInvoices />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path={`${authenticatedRoutes.DETAIL}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_invoice"]}>
                    <DetailInvoices />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_invoice"]}>
                    <InvoiceActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* SERVICES  */}
          <Route path={`${authenticatedRoutes.SERVICE}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_service"]}>
                  <Services />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_service"]}>
                  <AddEditService />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_service"]}>
                    <AddEditService />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_service"]}>
                    <ServiceActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* ACCESS CONTROL  */}
          <Route path={`${authenticatedRoutes.ACCESS_CONTROL}`}>
            <Route
              index
              element={
                <ProtectedRoute
                  requiredPermissions={["view_permission", "view_role"]}
                >
                  <AccessControl />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_role"]}>
                  <AddEditRole />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_role"]}>
                    <AddEditRole />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* PERMISSION  */}
          <Route
            path={`${authenticatedRoutes.PERMISSIONS}/${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
          >
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["view_permission"]}>
                  <PermissionActivityDetail />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* ROLE  */}
          <Route
            path={`${authenticatedRoutes.ROLES}/${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
          >
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["view_role"]}>
                  <RoleActivityDetail />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* TECH PROCUREMENTS  */}
          <Route path={`${authenticatedRoutes.TECH_PROCUREMENTS}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_techprocurement"]}>
                  <TechProcurements />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute
                  requiredPermissions={["create_techprocurement"]}
                >
                  <AddEditTechProcurements />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute
                    requiredPermissions={["update_techprocurement"]}
                  >
                    <AddEditTechProcurements />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute
                    requiredPermissions={["view_techprocurement"]}
                  >
                    <TechProcurementActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* WEB SERVICE  */}
          <Route path={`${authenticatedRoutes.WEB_SERVICE}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["list_webservice"]}>
                  <WebService />
                </ProtectedRoute>
              }
            />
            <Route
              path={authenticatedRoutes.ADD}
              element={
                <ProtectedRoute requiredPermissions={["create_webservice"]}>
                  <AddEditWebService />
                </ProtectedRoute>
              }
            />
            <Route path={`${authenticatedRoutes.EDIT}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["update_webservice"]}>
                    <AddEditWebService />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route
              path={`${authenticatedRoutes.ACTIVITY}${authenticatedRoutes.DETAIL}:id`}
            >
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={["view_webservice"]}>
                    <WebActivityDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* ACTIVITY */}
          <Route path={`${authenticatedRoutes.ACTIVITY}`}>
            <Route
              index
              element={
                <ProtectedRoute requiredPermissions={["view_activity"]}>
                  <Activity />
                </ProtectedRoute>
              }
            />
            {/* <Route path={`${authenticatedRoutes.DETAIL}:id`}>
              <Route
                index
                element={
                  <ProtectedRoute requiredPermissions={[""]}>
                    <DetailActivity />
                  </ProtectedRoute>
                }
              />
            </Route> */}
          </Route>
        </Route>

        {/* UNAUTHORIZED */}
        <Route
          path={unAuthenticatedRoutes.UNAUTHORIZED}
          element={<UnAuthorized />}
        />

        {/* 404  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
