import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import CustomButton from "components/UiComponents/CustomButton";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomRow from "components/UiComponents/CustomRow";
import CustomSelect from "components/UiComponents/CustomSelect";
import CustomSearchBar from "components/UiComponents/CustomSearchBar";
import CustomResult from "components/UiComponents/CustomResult";
import CustomTable from "../CustomTable";
import CustomTableCard from "../CustomTableCard";
import { useFetchTableListing } from "hooks/useTableListingApi";
import {
  BILLING_FREQUENCY,
  CONTRACT_DURATION,
  CONTRACT_STATUS,
  DELIVERABLE,
  SERVICE_STATUS,
  START_CRITERIA,
  USER_ROLE,
  pageSize,
} from "utils/Constants/Constant";
import "./CustomGridView.less";
import useMobileView from "hooks/useMobileView";

const CustomGridView = (props) => {
  const {
    listingApiRoute,
    searchCriteriaColumns,
    filters,
    searchBar,
    searchFilters,
    filterKeys,
    loadingStates,
    columns,
    titleButton,
    btnText,
    btnAction,
    btnIcon,
    pageTitle,
  } = props;

  const isMobileView = useMobileView();

  const [searchParams, setSearchParams] = useState({
    searchCriteria: {
      columns: searchCriteriaColumns,
    },
    sortCriteria: {
      createdAt: "DESC",
    },
    page: 1,
    limit: pageSize,
  });

  const {
    data: dataListing,
    isLoading: fetchingTableListing,
    isPlaceholderData,
    error,
  } = useFetchTableListing(searchParams, listingApiRoute);

  useEffect(() => {
    if (dataListing) {
      setPagination((prev) => ({
        ...prev,
        total: dataListing?.data?.total,
        currentPage: searchParams.page,
      }));
    }
  }, [dataListing, searchParams.page]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: pageSize,
    total: 0,
    showSizeChanger: false,
    showQuickJumper: false,
  });

  const FILTER_OPTIONS = {
    role: USER_ROLE,
    status: SERVICE_STATUS,
    ContractStatus: CONTRACT_STATUS,
    duration: CONTRACT_DURATION,
    deliverable: DELIVERABLE,
    startCriteria: START_CRITERIA,
    billingFrequency: BILLING_FREQUENCY,
  };

  const handleSearch = (searchTerm) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      searchCriteria: {
        ...prevParams.searchCriteria,
        term: searchTerm,
      },
      page: 1,
    }));
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 600), []);

  const handleTableChange = (newPagination, filters, sorter) => {
    const sortOrder = sorter?.order === "ascend" ? "ASC" : "DESC";

    setSearchParams((prev) => {
      const updatedParams = {
        ...prev,
        page: newPagination.current,
        limit: newPagination.pageSize,
      };

      if (sorter?.field) {
        updatedParams.sortCriteria = {
          ...prev.sortCriteria,
          [sorter.field]: sortOrder,
        };
      }

      return updatedParams;
    });
  };

  const handleFilterChange = (key, value) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      filterCriteria: {
        ...prevParams.filterCriteria,
        [key]: value,
      },
      page: 1,
    }));
  };

  const contentView = isMobileView ? (
    <>
      <CustomTableCard
        data={dataListing?.data?.items}
        columns={columns}
        loading={fetchingTableListing || isPlaceholderData}
        pagination={{
          current: pagination.currentPage,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChangePage={handleTableChange}
      />
    </>
  ) : (
    <CustomTable
      bordered
      data={dataListing?.data?.items}
      columns={columns}
      loading={fetchingTableListing || isPlaceholderData || loadingStates}
      onChange={handleTableChange}
      pagination={{
        ...pagination,
        current: pagination.currentPage,
      }}
    />
  );

  if (error)
    return (
      <CustomResult
        status="warning"
        title="Something went wrong!"
        subTitle={`An error occurred: ${error.message}`}
      />
    );

  return (
    <>
      {/* FILTERS & SEARCH FUNCTIONALITIES */}
      {titleButton && (
        <div className="topHeader top-row">
          <CustomRow>
            {pageTitle && (
              <CustomColumn
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="search-filter"
              >
                <CustomHeading className="title">{pageTitle}</CustomHeading>
              </CustomColumn>
            )}

            {btnText && (
              <CustomColumn
                className="topheader-btn"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div className="right-header">
                  <div className="aside">
                    {btnText && (
                      <CustomButton
                        htmlType="default"
                        className="deafult-btn"
                        icon={btnIcon}
                        onClick={btnAction}
                      >
                        {btnText}
                      </CustomButton>
                    )}
                  </div>
                </div>
              </CustomColumn>
            )}
          </CustomRow>
        </div>
      )}

      <CustomRow>
        {(searchFilters || searchBar || filters) && (
          <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="searchFilterWrapper">
              {searchBar && (
                <CustomSearchBar onSearch={debouncedHandleSearch} />
              )}
              {filterKeys && <h4>Filter</h4>}

              {filterKeys &&
                filterKeys.map(({ key, placeholder }) => {
                  const filterOptions = FILTER_OPTIONS[key];
                  if (!filterOptions) {
                    return null;
                  }

                  return (
                    <CustomSelect
                      key={key}
                      size="large"
                      placeholder={placeholder ? placeholder : "Select"}
                      popupMatchSelectWidth={true}
                      onChange={(value) => handleFilterChange(key, value)}
                      options={filterOptions.map((option) => ({
                        label: option.label,
                        value: option.value,
                      }))}
                    />
                  );
                })}
            </div>
          </CustomColumn>
        )}
      </CustomRow>

      {/* ONLY TABLE COMPONENT */}
      <div className="custom-table-grid-view-container">{contentView}</div>
    </>
  );
};

export default CustomGridView;
