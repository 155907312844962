import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import CustomButton from "components/UiComponents/CustomButton";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomForm from "components/UiComponents/CustomForm";
import CustomFormItem from "components/UiComponents/CustomFormItem";
import CustomInput from "components/UiComponents/CustomInput";
import CustomRow from "components/UiComponents/CustomRow";
import CustomSelect from "components/UiComponents/CustomSelect";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomTextarea from "components/UiComponents/CustomTextarea";
import CustomAutoComplete from "components/UiComponents/CustomAutoComplete";
import { REPORT_STATUS } from "utils/Constants/Constant";
import { authenticatedRoutes } from "utils/Constants/routes.constant";
import notificationService from "utils/Notification/Notification.service";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { useAddReport, useUpdateReport } from "hooks/useReportApi";
import { useAddAttachment, useDeleteAttachment } from "hooks/useAttachmentApi";
import { useFetchContract, useFetchContractById } from "hooks/useContractApi";
import { transformToOptions } from "utils/Helper/Services.helper";
import { fetchAPI } from "services/api.Service";
import { useFetchService, useFetchServiceById } from "hooks/useServiceApi";

const { Dragger } = Upload;

const AddEditReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const reportData = location.state;

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [selectedContract, setSelectedContract] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [contractSearchParams, setContractSearchParams] = useState({
    searchCriteria: {
      columns: ["title"],
    },
    sortCriteria: {
      createdAt: "DESC",
    },
    limit: 50,
  });
  const [serviceSearchParams, setServiceSearchParams] = useState({
    searchCriteria: {
      columns: ["name"],
    },
    sortCriteria: {
      createdAt: "DESC",
    },
    limit: 50,
  });

  const { mutate: addReport, isPending: addingReport } = useAddReport();
  const { mutate: updateReport, isPending: updatingReport } = useUpdateReport();
  const { mutate: addAttachment, isPending: addingAttachment } =
    useAddAttachment();
  const { mutate: deleteAttachment } = useDeleteAttachment();
  const { data: contractData } = useFetchContract(contractSearchParams);
  const { data: serviceData } = useFetchService(serviceSearchParams);

  const { refetch: fetchContract } = useFetchContractById(
    reportData?.contractId
  );
  const { refetch: fetchService } = useFetchServiceById(reportData?.serviceId);

  // Filtering attachments via attachment id
  const fetchAttachmentData = async (id) => {
    try {
      const response = await fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.ATTACHMENT.GET_ALL_ATTACHMENTS}/${id}`,
      });
      const attachment = await response?.data;

      const attachmentData = {
        uid: attachment.id,
        // name: attachment.url.split("/").pop(),
        name: attachment.name
          ? attachment.name
          : attachment.url.split("/").pop(),
        status: "done",
        url: attachment.url,
      };

      setFileList((prevFileList) => {
        // Filtering duplicate attachments (If any)
        if (!prevFileList.find((file) => file.uid === attachment.id)) {
          return [...prevFileList, attachmentData];
        }
        return prevFileList;
      });
    } catch (error) {}
  };

  const onFinish = (formData, attachmentIds) => {
    if (!reportData) {
      addReport(
        {
          attachmentId: attachmentIds,
          title: formData.title,
          description: formData.description,
          status: formData.status,
          contractId: selectedContract?.id,
          serviceId: selectedService?.id,
        },
        {
          onSuccess: () => {
            notificationService.success(`Report Added`);
            navigate(`/${authenticatedRoutes.REPORT}`);
          },
          onError: (error) => {
            notificationService.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : "Error adding report"
            );
          },
        }
      );
    } else {
      updateReport(
        {
          id: reportData?.id,
          attachmentId: attachmentIds,
          title: formData.title,
          description: formData.description,
          status: formData.status,
          contractId: selectedContract?.id,
          serviceId: selectedService?.id,
        },
        {
          onSuccess: () => {
            notificationService.success(`Report Edited`);
            navigate(`/${authenticatedRoutes.REPORT}`);
          },
          onError: () => {
            notificationService.error(`Error editing report`);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (reportData?.attachmentId) {
      setAttachmentIds(reportData.attachmentId);
      reportData?.attachmentId.forEach((id) => {
        fetchAttachmentData(id);
      });
    }
  }, [reportData?.attachmentId]);

  useEffect(() => {
    if (reportData) {
      fetchContract()
        .then((fetchedContract) => {
          form.setFieldsValue({
            contractId: fetchedContract?.data?.data?.title,
          });
        })
        .catch((error) => {
          console.error("Error fetching contract data:", error);
        });
    }
  }, [reportData?.contractId]);

  useEffect(() => {
    if (reportData) {
      fetchService()
        .then((fetchedservice) => {
          form.setFieldsValue({
            serviceId: fetchedservice?.data?.data?.name,
          });
        })
        .catch((error) => {
          console.error("Error fetching service data:", error);
        });
    }
  }, [reportData?.serviceId]);

  useEffect(() => {
    if (reportData) {
      // const contractIdToMatch = reportData?.contractId;
      // const matchingContract = contractData?.data?.items.find(
      //   (contract) => contract.id == contractIdToMatch
      // );
      form.setFieldsValue({
        title: reportData.title,
        description: reportData.description,
        status: reportData.status,
        // contractId: matchingContract
        //   ? matchingContract.title
        //   : reportData.contractId,
        // serviceId: reportData.serviceId,
        file: fileList,
      });
    }
  }, [reportData, form, fileList]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (file, onProgressCallback, onSuccess, onError) => {
    const formData = new FormData();
    formData.append("file", file);

    addAttachment(formData, {
      onSuccess: (response) => {
        clearInterval(interval);
        notificationService.success(`File Attached`);
        const newAttachmentId = response.data.id;

        setAttachmentIds((prevAttachmentIds) => [
          ...prevAttachmentIds,
          newAttachmentId,
        ]);

        // const attachmentData = {
        //   uid: newAttachmentId,
        //   name: file.name,
        //   status: "done",
        //   url: URL.createObjectURL(file),
        // };

        // setFileList((prevFileList) => [...prevFileList, attachmentData]);

        onSuccess(response);
      },
      onError: (error) => {
        clearInterval(interval);
        notificationService.error(`Error adding attachment`);

        onError(error);
      },
    });

    // Progress updates
    const fileSize = file.size;
    let bytesUploaded = 0;
    const interval = setInterval(() => {
      bytesUploaded += 1024 * 1024;
      const progress = Math.min((bytesUploaded / fileSize) * 100, 100);
      onProgressCallback(progress);

      // Stop interval when upload is complete
      if (bytesUploaded >= fileSize) {
        clearInterval(interval);
      }
    }, 1000);
  };

  // Deleting Attachment
  const handleRemove = (file) => {
    setFileList((prevFileList) =>
      prevFileList.filter((item) => item.uid !== file.uid)
    );
    setAttachmentIds((prevAttachmentIds) =>
      prevAttachmentIds.filter((id) => id !== file.uid)
    );

    deleteAttachment(file?.uid, {
      onSuccess: () => {
        notificationService.success(`Attachment removed`);
      },
      onError: (error) => {
        notificationService.error(`Error removing atachment`);
      },
    });
  };

  const fileProps = {
    name: "file",
    multiple: false,
    customRequest: ({ file, onProgress, onSuccess, onError }) => {
      handleUpload(
        file,
        (percentCompleted) => {
          onProgress({ percent: percentCompleted });
        },
        onSuccess,
        onError
      );
    },
    onChange(info) {
      const { status, response } = info.file;

      if (status === "done") {
        const attachmentData = response.data;

        // Update file status to "done" and add attachment data
        const updatedFileList = info.fileList.map((file) => {
          if (file.uid === info.file.uid) {
            return { ...file, status: "done", ...attachmentData };
          }
          return file;
        });

        setFileList(updatedFileList);
      } else {
        setFileList(info.fileList);
      }

      // Log status for debugging
      console.log(status, "status");
    },
    onRemove: handleRemove,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSubmit = (formData) => {
    onFinish(formData, attachmentIds);
  };

  // SELECT CUSTOMER FILTER
  const contractOptions = transformToOptions(contractData?.data?.items, false);
  // SELECT SERVICE FILTER
  const serviceOptions = transformToOptions(serviceData?.data?.items, false);

  const handleContractSearch = useCallback(
    debounce((searchTerm) => {
      setContractSearchParams((prevParams) => ({
        ...prevParams,
        searchCriteria: {
          ...prevParams.searchCriteria,
          term: searchTerm,
        },
      }));
    }, 400),
    []
  );

  const handleServiceSearch = useCallback(
    debounce((searchTerm) => {
      setServiceSearchParams((prevParams) => ({
        ...prevParams,
        searchCriteria: {
          ...prevParams.searchCriteria,
          term: searchTerm,
        },
      }));
    }, 400),
    []
  );

  return (
    <div className="wrapper addEditReport edit">
      <div className="cont">
        <CustomHeading className="title">
          {reportData ? "Edit " : "Add "} Report
        </CustomHeading>
        <CustomForm
          form={form}
          name="addEditReportForm"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <CustomRow gutter={[16, 16]}>
            <CustomColumn xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItem
                name="title"
                label="Title"
                colon={false}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                ]}
              >
                <CustomInput placeholder="" />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItem
                label="Status"
                name="status"
                colon={false}
                rules={[
                  {
                    required: true,
                    message: "Status is required",
                  },
                ]}
              >
                <CustomSelect
                  size="large"
                  defaultActiveFirstOption="true"
                  popupMatchSelectWidth="true"
                  options={REPORT_STATUS.map((status) => ({
                    label: status.label,
                    value: status.value,
                  }))}
                />
              </CustomFormItem>
            </CustomColumn>
            <CustomColumn xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItem
                label="Contract"
                name="contractId"
                colon={false}
                rules={[
                  {
                    required: true,
                    message: "Contract is required",
                  },
                ]}
              >
                <CustomAutoComplete
                  backfill={true}
                  options={contractOptions}
                  onSelect={(value, option) => {
                    const selectedContract = contractData?.data?.items.find(
                      (contract) => contract.title === value
                    );

                    if (selectedContract) {
                      setSelectedContract(selectedContract);
                    }
                  }}
                  onSearch={handleContractSearch}
                  filterOption={false}
                  notFoundContent="No contracts found"
                  // onBlur={handleBlur}
                />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItem
                label="Service"
                name="serviceId"
                colon={false}
                rules={[
                  {
                    required: true,
                    message: "Service is required",
                  },
                ]}
              >
                <CustomAutoComplete
                  backfill={true}
                  options={serviceOptions}
                  onSelect={(value, option) => {
                    const selectedService = serviceData?.data?.items.find(
                      (service) => service.name === value
                    );

                    if (selectedService) {
                      setSelectedService(selectedService);
                    }
                  }}
                  onSearch={handleServiceSearch}
                  filterOption={false}
                  notFoundContent="No service found"
                  // onBlur={handleBlur}
                />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <CustomFormItem
                label="Attachments"
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Attachments are Required",
                  },
                ]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Dragger
                  {...fileProps}
                  fileList={fileList}
                  style={{ maxHeight: "200px" }}
                  listType="picture"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Please upload only a single file.
                  </p>
                </Dragger>
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
              <CustomFormItem
                name="description"
                label="Description"
                colon={false}
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: "Description is required",
                  },
                ]}
              >
                <CustomTextarea
                  allowClear
                  rows={3}
                  placeholder="Enter Details"
                />
              </CustomFormItem>
            </CustomColumn>

            <CustomColumn
              xs={{ span: 10, offset: 14 }}
              sm={{ span: 10, offset: 14 }}
              md={{ span: 10, offset: 14 }}
              lg={{ span: 10, offset: 14 }}
              xl={{ span: 4, offset: 20 }}
            >
              <div className="text-right update-btn">
                <CustomButton
                  htmlType="submit"
                  className="default-btn update-btn"
                  disabled={addingAttachment}
                  loading={addingReport || updatingReport}
                >
                  {reportData ? "Edit " : "Add "} Report
                </CustomButton>
              </div>
            </CustomColumn>
          </CustomRow>
        </CustomForm>
      </div>
    </div>
  );
};

export default AddEditReport;
