import DashboardIcon from "assets/SvgFiles/DashboardIcon";
import CustomerIcon from "assets/SvgFiles/CustomerIcon";
import ProjectsIcon from "assets/SvgFiles/ProjectIcon";
import ReportIcon from "assets/SvgFiles/ReportIcon";
import InvoiceIcon from "assets/SvgFiles/InvoiceIcon";
import ServiceIcon from "assets/SvgFiles/ServiceIcon";
import AccessControlIcon from "assets/SvgFiles/AccessControlIcon";
import UserIcon from "assets/SvgFiles/UserIcon";
import PlansIcon from "assets/SvgFiles/PlansIcon";
import ContractIcon from "assets/SvgFiles/ContractsIcon";
import TechProcurementIcon from "assets/SvgFiles/TechProcurementIcon";
import WebServiceIcon from "assets/SvgFiles/WebServiceIcon";
import ActivityIcon from "assets/SvgFiles/ActivityIcon";

export const unAuthenticatedRoutes = {
  LOGIN: "login",
  FORGOT_PASSWORD: "forgot-password",
  CHECK_EMAIL: "check-email",
  RESET_PASSWORD: "reset-password",
  UNAUTHORIZED: "unauthorized",
};

export const authenticatedRoutes = {
  DASHBOARD: "/",
  USER: "user",
  CUSTOMERS: "customers",
  PROJECT: "project",
  REPORT: "report",
  INVOICE: "invoice",
  CONTRACTS: "contracts",
  PLAN: "plan",
  SERVICE: "service",
  ACCESS_CONTROL: "access-control-management",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  TECH_PROCUREMENTS: "tech-procurements",
  WEB_SERVICE: "web-service",
  ACTIVITY: "activity/",
  ADD: "add",
  EDIT: "edit/",
  DETAIL: "detail/",
  CHANGE_PASSWORD: "change-password",
};

export const permissionsToRoutes = {
  [authenticatedRoutes.DASHBOARD]: [], // This route does not require any permision
  [authenticatedRoutes.USER]: [
    "view_user",
    "list_user",
    "create_user",
    "update_user",
    "delete_user",
  ],
  [authenticatedRoutes.CUSTOMERS]: [
    "view_customer",
    "list_customer",
    "create_customer",
    "update_customer",
    "delete_customer",
  ],
  [authenticatedRoutes.CONTRACTS]: [
    "view_contract",
    "list_contract",
    "create_contract",
    "update_contract",
    "delete_contract",
  ],
  [authenticatedRoutes.PLAN]: [
    "view_plan",
    "list_plan",
    "create_plan",
    "update_plan",
    "delete_plan",
  ],
  [authenticatedRoutes.PROJECT]: [
    "view_project",
    "list_project",
    "create_project",
    "update_project",
    "delete_project",
  ],
  [authenticatedRoutes.REPORT]: [
    "view_report",
    "list_report",
    "create_report",
    "update_report",
    "delete_report",
  ],
  [authenticatedRoutes.INVOICE]: [
    "view_invoice",
    "list_invoice",
    "create_invoice",
    "update_invoice",
    "delete_invoice",
  ],
  [authenticatedRoutes.SERVICE]: [
    "view_service",
    "list_service",
    "create_service",
    "update_service",
    "delete_service",
  ],
  [authenticatedRoutes.TECH_PROCUREMENTS]: [
    "view_techprocurement",
    "list_techprocurement",
    "create_techprocurement",
    "update_techprocurement",
    "delete_techprocurement",
  ],
  [authenticatedRoutes.WEB_SERVICE]: [
    "view_webservice",
    "list_webservice",
    "create_webservice",
    "update_webservice",
    "delete_webservice",
  ],
  [authenticatedRoutes.ACTIVITY]: [
    "view_activity",
    "list_activity",
    "create_activity",
    "update_activity",
    "delete_activity",
  ],
  [authenticatedRoutes.ACCESS_CONTROL]: [
    "view_permission",
    "list_permission",
    "create_permission",
    "update_permission",
    "delete_permission",
    "view_role",
    "list_role",
    "create_role",
    "update_role",
    "delete_role",
  ],
};

export const AllMenusItems = [
  {
    label: "Dashboard",
    linkTo: authenticatedRoutes.DASHBOARD,
    selectedOptionKey: "dashboard",
    icon: <DashboardIcon />,
  },
  {
    label: "Users",
    linkTo: authenticatedRoutes.USER,
    selectedOptionKey: "users",
    icon: <UserIcon />,
  },
  {
    label: "Customers",
    linkTo: authenticatedRoutes.CUSTOMERS,
    selectedOptionKey: "customer",
    icon: <CustomerIcon />,
  },
  {
    label: "Contracts",
    linkTo: authenticatedRoutes.CONTRACTS,
    selectedOptionKey: "contracts",
    icon: <ContractIcon />,
  },
  {
    label: "Plans",
    linkTo: authenticatedRoutes.PLAN,
    selectedOptionKey: "plan",
    icon: <PlansIcon />,
  },
  {
    label: "Projects",
    linkTo: authenticatedRoutes.PROJECT,
    selectedOptionKey: "project",
    icon: <ProjectsIcon />,
  },
  {
    label: "Reports",
    linkTo: authenticatedRoutes.REPORT,
    selectedOptionKey: "report",
    icon: <ReportIcon />,
  },
  {
    label: "Invoices",
    linkTo: authenticatedRoutes.INVOICE,
    selectedOptionKey: "invoice",
    icon: <InvoiceIcon />,
  },
  {
    label: "Services",
    linkTo: authenticatedRoutes.SERVICE,
    selectedOptionKey: "service",
    icon: <ServiceIcon />,
  },
  {
    label: "Access Control",
    linkTo: authenticatedRoutes.ACCESS_CONTROL,
    selectedOptionKey: "accessControl",
    icon: <AccessControlIcon />,
  },
  {
    label: "Tech Procurements",
    linkTo: authenticatedRoutes.TECH_PROCUREMENTS,
    selectedOptionKey: "techProcurements",
    icon: <TechProcurementIcon />,
  },
  {
    label: "Web Services",
    linkTo: authenticatedRoutes.WEB_SERVICE,
    selectedOptionKey: "webService",
    icon: <WebServiceIcon />,
  },
  {
    label: "Activity",
    linkTo: authenticatedRoutes.ACTIVITY,
    selectedOptionKey: "activity",
    icon: <ActivityIcon />,
  },
];
