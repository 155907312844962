import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { fetchAPI } from "services/api.Service";

// FETCH SERVICES
export const useFetchService = (serviceParams) => {
  return useQuery({
    queryKey: ["Services", serviceParams],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.SERVICE.GET_ALL_SERVICES,
        queryParams: serviceParams,
        data: {},
        signal,
      }),
    placeholderData: keepPreviousData,
  });
};

// FETCH SERVICES BY ID
export const useFetchServiceById = (id) => {
  return useQuery({
    queryKey: ["Services", id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.SERVICE.GET_ALL_SERVICES}/${id}`,
        queryParams: {},
      }),
    enabled: false,
  });
};

// ADD SERVICE
export const useAddService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.SERVICE.GET_ALL_SERVICES,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Services"]);
    },
  });
};

// UPDATE SERVICE
export const useUpdateService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.SERVICE.GET_ALL_SERVICES}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Services"]);
    },
  });
};

// DELETE SERVICE
export const useDeleteService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.SERVICE.GET_ALL_SERVICES}/${id}`,
        queryParams: {},
        // data: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Services"]);
    },
  });
};
