import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Skeleton, Space } from "antd";
import moment from "moment";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomRow from "components/UiComponents/CustomRow";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomDivider from "components/UiComponents/CustomDivider";
import { DATE_FORMAT } from "utils/Constants/Constant";
import { useFetchReportById } from "hooks/useReportApi";

const ReportActivityDetail = () => {
  const location = useLocation();
  const [reportData, setReportData] = useState(null);

  const data = location?.state;

  const { refetch: fetchReport } = useFetchReportById(data?.objectId);

  useEffect(() => {
    if (data) {
      fetchReport()
        .then((fetchedData) => {
          setReportData(fetchedData?.data?.data);
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
  }, [data, fetchReport]);

  return (
    <div className="wrapper detailPage reportDetail">
      <div className="cont">
        <Space className="w-100 space-between" align="center ">
          <CustomHeading backButton={true} className="title">
            Report Details
          </CustomHeading>
        </Space>

        <CustomDivider className="my-20" />
        <Skeleton
          active
          loading={!data}
          title={{ width: "50%" }}
          paragraph={{
            rows: 4,
            width: "40%",
          }}
        >
          <section className="topSection">
            <CustomRow gutter={[16, 25]}>
              <CustomColumn xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomHeading>Title</CustomHeading>
                <p>{reportData?.title}</p>
              </CustomColumn>

              <CustomColumn xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomHeading>Description</CustomHeading>

                <p>{reportData?.description}</p>
              </CustomColumn>

              <CustomColumn xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomHeading>Created At</CustomHeading>
                <p>{moment(reportData?.createdAt).format(DATE_FORMAT)}</p>
              </CustomColumn>

              <CustomColumn xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomHeading>Updated At</CustomHeading>
                <p>{moment(reportData?.updatedAt).format(DATE_FORMAT)}</p>
              </CustomColumn>
            </CustomRow>
          </section>
        </Skeleton>

        <CustomDivider className="my-30" />
      </div>
    </div>
  );
};

export default ReportActivityDetail;
