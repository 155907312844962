import { Input } from "antd";
import React, { memo } from "react";
import "./CustomTextarea.less";

const CustomTextarea = (props) => {
  const { TextArea } = Input;
  return (
    <span className="customTextareaWrapper">
      <TextArea {...props} />
    </span>
  );
};

export default memo(CustomTextarea);
