// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customTextareaWrapper textarea {
  padding: 8px 12px 8px 12px;
  color: #000000;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0em;
}
.customTextareaWrapper textarea::placeholder {
  color: #00000080;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 400;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomTextarea/CustomTextarea.less"],"names":[],"mappings":"AAEA;EAEI,0BAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAGI;EACE,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,UAAA;AADN","sourcesContent":["@import url(\"../../../assets/Styles/variables.less\");\n\n.customTextareaWrapper {\n  textarea {\n    padding: 8px 12px 8px 12px;\n    color: @black-color;\n    font-size: 14px;\n    font-family: @primary-font-family;\n    font-weight: 400;\n    letter-spacing: 0em;\n    &::placeholder {\n      color: @placeholder;\n      font-size: 14px;\n      font-family: @primary-font-family;\n      font-weight: 400;\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
