import React from "react";

function DeleteIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.875 4.23584C12.5162 4.00209 10.1433 3.88167 7.7775 3.88167C6.375 3.88167 4.9725 3.95251 3.57 4.09417L2.125 4.23584M6.02083 3.52042L6.17667 2.59251C6.29 1.91959 6.375 1.41667 7.57208 1.41667H9.42792C10.625 1.41667 10.7171 1.94792 10.8233 2.59959L10.9792 3.52042M13.3521 6.47417L12.8917 13.6071C12.8138 14.7192 12.75 15.5833 10.7737 15.5833H6.22625C4.25 15.5833 4.18625 14.7192 4.10833 13.6071L3.64792 6.47417M7.31708 11.6875H9.67583M6.72917 8.85417H10.2708"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon;
