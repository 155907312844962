import React from "react";

function AccessControlIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1650 4750 c-165 -23 -325 -72 -464 -142 -336 -169 -600 -471 -717
-820 -62 -183 -70 -252 -76 -604 l-5 -321 -49 -6 c-119 -14 -236 -103 -296
-225 l-38 -76 0 -935 c0 -882 1 -939 18 -989 36 -103 132 -202 237 -245 l55
-22 1475 -3 c1093 -2 1489 0 1530 8 120 26 233 114 282 221 13 27 27 48 33 46
5 -2 21 -38 35 -81 25 -79 55 -121 98 -137 33 -13 255 -11 289 2 49 18 72 50
100 135 26 80 27 82 67 94 23 7 75 28 117 47 l76 35 71 -36 c78 -39 134 -46
180 -22 15 8 64 52 108 98 116 118 121 145 55 277 l-33 64 35 76 c19 42 40 94
47 117 12 39 15 42 84 63 142 46 156 67 156 244 0 185 -14 208 -155 253 l-70
22 -26 68 c-14 38 -35 90 -48 115 l-23 46 35 69 c19 38 36 81 37 94 6 72 2 81
-92 177 -51 52 -105 101 -120 109 -43 23 -83 17 -166 -25 l-77 -39 -40 21
c-22 12 -74 33 -115 48 l-75 26 -21 64 c-30 93 -50 127 -87 146 -37 19 -176
29 -264 18 -76 -9 -111 -44 -143 -140 -33 -102 -38 -105 -72 -43 -61 112 -184
201 -295 214 l-51 7 -5 336 c-4 297 -7 346 -26 426 -60 254 -161 455 -325 644
-205 237 -499 406 -808 466 -99 19 -347 28 -438 15z m357 -166 c273 -40 508
-159 708 -359 151 -150 244 -300 311 -500 53 -159 64 -258 64 -583 l0 -283
-142 3 -143 3 -6 315 c-5 278 -8 324 -26 391 -81 292 -266 515 -534 643 -123
59 -228 86 -366 93 -476 25 -898 -294 -1009 -764 -12 -52 -17 -142 -21 -376
l-5 -308 -141 3 -142 3 0 305 c1 252 4 320 18 390 62 296 210 551 427 734 286
242 643 345 1007 290z m-34 -448 c324 -62 584 -319 653 -645 10 -47 14 -146
14 -349 l0 -282 -817 2 -818 3 0 295 c0 317 4 344 59 482 75 184 231 346 418
432 154 71 327 93 491 62z m1378 -1464 c37 -19 64 -43 87 -76 17 -27 32 -52
32 -57 0 -5 -12 -15 -27 -22 -25 -13 -32 -12 -107 24 -127 62 -160 53 -283
-73 -119 -122 -125 -148 -65 -274 l38 -79 -28 -55 c-15 -30 -35 -77 -44 -105
-9 -27 -21 -55 -28 -61 -6 -7 -42 -22 -81 -34 -96 -32 -131 -67 -140 -144 -4
-32 -5 -98 -3 -147 7 -108 28 -150 91 -173 23 -9 64 -24 90 -33 48 -18 49 -19
81 -103 18 -47 39 -98 48 -115 15 -29 15 -32 -23 -107 -42 -83 -48 -123 -25
-166 8 -15 57 -69 109 -119 93 -92 95 -93 143 -93 36 0 70 10 124 35 65 32 77
35 102 24 37 -15 36 -38 -5 -91 -38 -50 -85 -84 -136 -98 -23 -6 -544 -10
-1486 -10 -1185 1 -1457 3 -1488 14 -55 20 -103 61 -134 114 l-28 47 -3 895
c-2 661 0 906 9 938 15 53 81 129 134 153 38 18 106 19 1516 19 l1477 0 53
-28z m668 -84 c14 -43 37 -89 51 -103 14 -14 61 -38 105 -53 44 -16 108 -43
142 -60 34 -18 77 -32 95 -32 18 0 67 17 111 37 l78 37 55 -55 55 -55 -41 -85
c-48 -98 -48 -107 9 -224 22 -44 45 -102 52 -129 19 -75 56 -110 150 -141 l80
-27 -3 -80 -3 -80 -80 -29 c-95 -33 -115 -53 -146 -146 -12 -37 -40 -104 -61
-148 -45 -93 -45 -112 7 -215 l34 -67 -57 -56 -57 -55 -66 33 c-101 52 -121
52 -214 7 -44 -21 -111 -49 -148 -61 -93 -31 -113 -51 -146 -146 l-29 -80 -80
-3 -80 -3 -27 80 c-14 44 -37 91 -51 105 -14 13 -56 34 -92 46 -37 12 -97 37
-134 55 -104 52 -119 53 -217 5 l-85 -41 -55 55 -55 55 37 78 c20 44 37 93 37
111 0 18 -15 63 -34 101 -19 37 -45 97 -56 132 -39 116 -46 123 -165 162 l-70
24 -3 75 c-3 86 -3 86 83 112 96 29 117 51 154 157 18 51 46 117 62 147 38 72
37 106 -8 197 l-38 75 58 57 57 58 75 -38 c41 -20 89 -37 105 -37 17 0 67 17
113 39 45 21 112 49 150 61 79 26 106 54 133 145 26 86 26 86 112 83 l75 -3
26 -77z"
        />
        <path
          d="M1671 2375 c-183 -42 -323 -124 -440 -259 -299 -347 -227 -885 152
-1145 212 -145 503 -175 739 -75 219 92 389 289 454 525 25 89 25 290 1 379
-71 263 -260 465 -517 551 -79 26 -113 32 -210 35 -78 3 -136 -1 -179 -11z
m283 -160 c171 -36 343 -170 418 -325 107 -222 84 -459 -66 -657 -79 -104
-198 -183 -331 -218 -80 -21 -224 -21 -306 0 -240 62 -425 272 -459 521 -56
416 328 767 744 679z"
        />
        <path
          d="M1934 1708 l-174 -172 -120 118 c-114 113 -121 118 -152 112 -42 -8
-68 -39 -68 -80 0 -28 20 -52 158 -189 131 -130 163 -157 187 -157 24 0 63 34
238 208 115 114 213 217 217 229 12 28 -6 80 -30 93 -10 6 -33 10 -51 10 -28
0 -53 -21 -205 -172z"
        />
        <path
          d="M3744 2281 c-250 -68 -444 -269 -499 -517 -37 -162 -2 -369 85 -502
137 -212 341 -326 585 -325 163 0 291 45 423 148 164 128 254 316 256 530 0
124 -14 184 -71 304 -77 162 -227 292 -403 351 -95 32 -279 37 -376 11z m299
-156 c263 -68 437 -339 384 -599 -9 -42 -27 -102 -42 -133 l-25 -56 -55 52
c-54 51 -142 101 -179 101 -16 0 -16 4 8 35 45 59 66 115 66 181 0 89 -24 150
-85 210 -65 65 -129 89 -224 82 -227 -17 -345 -271 -211 -456 34 -47 35 -52
18 -52 -31 0 -142 -63 -183 -104 l-45 -45 -15 23 c-52 79 -78 249 -56 358 40
190 193 351 383 402 70 19 190 20 261 1z m-36 -329 c42 -45 45 -104 10 -157
-27 -40 -89 -66 -132 -55 -40 10 -92 66 -100 108 -23 124 135 199 222 104z
m118 -475 c41 -18 89 -61 106 -93 8 -14 2 -23 -32 -47 -114 -83 -283 -107
-445 -62 -56 16 -158 75 -162 94 -5 23 76 101 118 114 20 6 110 11 205 12 145
0 176 -2 210 -18z"
        />
      </g>
    </svg>
  );
}

export default AccessControlIcon;
