import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/UiComponents/CustomButton";
import CustomResult from "components/UiComponents/CustomResult";
import { authenticatedRoutes } from "utils/Constants/routes.constant";
import "./UnAuthorized.less";

const UnAuthorized = () => {
  const navigate = useNavigate();

  return (
    <section className="unAuthorized">
      <CustomResult
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <CustomButton
            onClick={() => {
              navigate(`${authenticatedRoutes.DASHBOARD}`);
            }}
          >
            Back Home
          </CustomButton>
        }
      />
    </section>
  );
};

export default UnAuthorized;
