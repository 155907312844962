import React from "react";

function ContractIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1240 5033 c-113 -11 -223 -37 -247 -57 -35 -30 -33 -76 3 -124 l28
-36 -29 -20 c-233 -168 -380 -304 -502 -467 -206 -275 -329 -562 -390 -914
-12 -73 -17 -156 -17 -305 0 -218 11 -312 59 -501 46 -182 145 -418 184 -439
35 -18 82 -8 104 22 20 27 20 29 3 130 -24 139 -32 387 -17 529 63 598 379
1145 849 1467 l88 60 47 -59 c79 -98 113 -93 208 33 91 119 221 252 314 319
135 98 135 98 135 129 0 44 -43 86 -133 130 -175 86 -449 127 -687 103z m350
-168 c99 -15 230 -51 230 -63 -1 -4 -21 -23 -46 -42 -49 -38 -162 -153 -236
-240 l-47 -55 -18 25 c-59 83 -89 87 -187 30 -502 -294 -891 -861 -995 -1452
-39 -217 -34 -202 -42 -128 -56 536 161 1125 553 1496 103 98 152 135 291 228
120 80 122 82 125 122 2 28 -2 45 -13 54 -8 7 -15 16 -15 21 0 20 275 23 400
4z"
        />
        <path
          d="M1031 3790 c-137 -29 -235 -151 -235 -295 -1 -256 301 -390 500 -222
17 14 45 53 63 86 l32 61 55 0 54 0 -2 -222 -3 -221 -51 -17 c-68 -23 -121
-58 -169 -111 -88 -95 -120 -185 -113 -314 5 -94 42 -186 102 -252 l36 -40
-42 -19 c-224 -101 -408 -351 -425 -580 -5 -68 -5 -71 22 -92 28 -22 30 -22
501 -22 l473 0 -10 -32 c-18 -63 -14 -128 10 -151 l22 -22 709 0 709 0 22 22
c24 23 28 88 10 151 l-10 32 473 0 c464 0 474 0 500 21 21 17 26 29 26 68 0
70 -25 155 -75 256 -73 150 -179 259 -322 332 l-71 37 35 39 c94 104 128 273
83 407 -44 130 -139 227 -264 270 l-51 17 -3 221 -2 222 54 0 55 0 32 -61 c18
-33 46 -72 63 -86 154 -130 385 -84 473 94 92 189 -46 420 -255 426 -119 4
-221 -54 -276 -155 l-31 -56 -113 -4 c-110 -3 -114 -4 -138 -31 l-24 -28 0
-270 c0 -248 -1 -270 -17 -275 -136 -44 -232 -123 -285 -233 -27 -56 -33 -80
-36 -157 -6 -130 25 -221 105 -305 l34 -36 -43 -19 c-76 -34 -157 -92 -223
-160 l-65 -67 -40 18 c-22 10 -40 20 -40 22 0 3 16 24 35 47 19 23 47 69 62
102 25 53 28 70 28 169 0 93 -3 117 -23 160 -54 116 -137 194 -254 237 l-58
21 0 216 0 216 45 18 c65 27 141 114 161 185 56 193 -86 386 -286 386 -200 0
-343 -192 -286 -386 20 -71 96 -158 161 -185 l45 -18 0 -216 0 -216 -58 -21
c-117 -43 -200 -121 -254 -237 -20 -43 -23 -67 -23 -160 0 -99 3 -116 28 -169
15 -33 43 -79 62 -102 19 -23 35 -44 35 -47 0 -2 -18 -12 -40 -22 l-40 -18
-65 67 c-66 68 -147 126 -223 160 l-42 19 33 36 c82 91 111 176 105 305 -3 77
-9 101 -36 157 -53 110 -149 189 -284 233 -17 5 -18 27 -18 275 l0 270 -24 28
c-24 27 -28 28 -138 31 l-114 4 -28 54 c-61 116 -196 180 -325 154z m137 -177
c59 -37 79 -129 42 -190 -25 -41 -68 -63 -124 -63 -85 0 -151 89 -127 170 27
92 131 133 209 83z m1464 0 c59 -29 84 -112 52 -178 -50 -106 -215 -99 -253
11 -27 78 7 152 84 179 42 14 72 11 117 -12z m1471 -1 c15 -9 37 -36 48 -60
29 -58 19 -104 -31 -154 -33 -33 -44 -38 -84 -38 -86 0 -146 55 -146 133 0
115 112 177 213 119z m-2418 -811 c100 -45 155 -131 155 -241 0 -158 -122
-270 -280 -258 -132 10 -228 106 -238 238 -8 111 41 202 137 252 66 34 162 38
226 9z m1973 -7 c96 -48 148 -143 140 -254 -10 -132 -106 -228 -238 -238 -72
-5 -129 11 -182 51 -116 89 -132 267 -34 384 70 83 211 108 314 57z m-993
-198 c50 -21 106 -77 131 -131 60 -127 6 -280 -121 -348 -57 -31 -173 -30
-231 0 -54 29 -117 101 -132 151 -36 121 3 229 111 312 51 38 169 46 242 16z
m-924 -482 c30 -9 84 -32 121 -51 69 -36 188 -135 188 -157 0 -7 -16 -28 -36
-47 -19 -18 -56 -64 -81 -101 l-47 -68 -442 0 -443 0 10 33 c17 61 71 156 121
212 87 96 221 172 350 194 68 13 190 5 259 -15z m1960 0 c188 -55 360 -220
408 -391 l10 -33 -443 0 -442 0 -47 68 c-25 37 -62 83 -81 101 -20 19 -36 40
-36 47 0 22 119 121 188 157 65 34 144 61 207 71 51 7 179 -3 236 -20z m-1016
-199 c171 -36 335 -163 408 -315 20 -41 39 -85 42 -97 l6 -23 -581 0 -581 0 6
23 c23 80 82 176 154 247 152 151 341 208 546 165z"
        />
        <path
          d="M4690 2933 c-17 -22 -21 -36 -16 -68 35 -243 38 -287 33 -455 -6
-203 -35 -378 -93 -560 -135 -428 -412 -808 -762 -1048 l-88 -60 -47 59 c-79
98 -113 93 -208 -33 -81 -108 -268 -294 -344 -343 -84 -54 -105 -74 -105 -105
0 -39 32 -75 103 -114 158 -87 314 -120 572 -120 209 0 349 21 392 59 35 29
33 75 -3 123 l-28 36 29 20 c152 109 246 187 331 272 248 250 423 554 513 894
53 200 65 294 65 520 0 218 -11 312 -59 501 -46 179 -145 418 -183 438 -37 20
-79 13 -102 -16z m189 -916 c5 -400 -147 -840 -397 -1152 -131 -164 -261 -280
-456 -410 -117 -77 -121 -82 -124 -119 -2 -25 2 -44 14 -57 9 -11 15 -22 12
-25 -13 -12 -268 -16 -358 -5 -94 12 -232 44 -259 61 -11 6 -7 14 20 36 48 39
196 188 251 254 l47 55 18 -25 c60 -84 88 -88 195 -25 166 97 343 246 477 400
263 304 445 676 510 1047 39 217 34 202 42 128 4 -36 7 -109 8 -163z"
        />
      </g>
    </svg>
  );
}

export default ContractIcon;
