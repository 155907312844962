import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import { fetchAPI } from "services/api.Service";

// FETCH REPORT
export const useFetchReport = (userParams) => {
  return useQuery({
    queryKey: ["Report", userParams],
    queryFn: async ({ signal }) =>
      fetchAPI({
        method: "GET",
        url: SERVICE_URL.REPORT.GET_ALL_REPORTS,
        queryParams: userParams,
        data: {},
        signal,
      }),
    placeholderData: keepPreviousData,
  });
};

// FETCH REPORT BY ID
export const useFetchReportById = (id) => {
  return useQuery({
    queryKey: ["Report", id],
    queryFn: () =>
      fetchAPI({
        method: "GET",
        url: `${SERVICE_URL.REPORT.GET_ALL_REPORTS}/${id}`,
        queryParams: {},
      }),
    enabled: false,
  });
};

// ADD REPORT
export const useAddReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) =>
      fetchAPI({
        method: "POST",
        url: SERVICE_URL.REPORT.GET_ALL_REPORTS,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Report"]);
    },
  });
};

// UPDATE REPORT
export const useUpdateReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }) =>
      fetchAPI({
        method: "PUT",
        url: `${SERVICE_URL.REPORT.GET_ALL_REPORTS}/${id}`,
        queryParams: {},
        data: data,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Report"]);
    },
  });
};

// DELETE REPORT
export const useDeleteReport = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) =>
      fetchAPI({
        method: "DELETE",
        url: `${SERVICE_URL.REPORT.GET_ALL_REPORTS}/${id}`,
        queryParams: {},
        // data: {},
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["Report"]);
    },
  });
};
