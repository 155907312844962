import React, { memo, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dropdown, Layout, Modal } from "antd";
import { LogoutOutlined, LockOutlined } from "@ant-design/icons";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomRow from "components/UiComponents/CustomRow/index.jsx";
import CustomColumn from "components/UiComponents/CustomColumn/index.jsx";
import helperFunction from "utils/Helper/Functions.helper";
import {
  authenticatedRoutes,
  unAuthenticatedRoutes,
} from "utils/Constants/routes.constant";
import authSlice from "../../redux/Slices/auth.Slice";
import userSlice from "../../redux/Slices/user.Slice";
import logo from "assets/Images/preloader.png";
import { useAuth } from "hooks/useAuth";
import "./Header.less";

const TopHeader = () => {
  const { Header } = Layout;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = authSlice.actions;
  const { logoutMe } = userSlice.actions;
  const { confirm } = Modal;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { userObj } = useAuth();

  const showPromiseConfirm = () => {
    confirm({
      title: `Logout!`,
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: "Are you sure you want to Logout?",
      okText: "Yes",
      cancelText: "No",
      icon: (
        <div className="icon-wrapper">
          <LogoutOutlined />
        </div>
      ),
      async onOk() {
        logoutCall();
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  };

  const logoutCall = useCallback(async () => {
    helperFunction.deleteLocalStorage("jwtToken");
    dispatch(logout());
    dispatch(logoutMe());
    navigate(`${unAuthenticatedRoutes.LOGIN}`);
  }, [dispatch, logout, logoutMe, navigate]);

  const items = [
    {
      key: "1",
      label: "Change Password",
      icon: <LockOutlined />,
      onClick: () => {
        navigate(`${authenticatedRoutes.CHANGE_PASSWORD}`);
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        showPromiseConfirm();
      },
    },
  ];

  // const content = (
  //   <>
  //     <div className="notificationWrapper">
  //       <Skeleton
  //         loading={false}
  //         active
  //         paragraph={{
  //           rows: 3,
  //           width: "100%",
  //         }}
  //       >
  //         {NotificationsData.length > 0 ? (
  //           NotificationsData.map((list) => (
  //             <Link key={list.id} to={`#`}>
  //               <div className="cont">
  //                 <div className="left">
  //                   <img src={profileImg} alt="user" height={40} width={40} />
  //                 </div>
  //                 <div className="mid">
  //                   <h4>{list?.type}</h4>
  //                   <p>{list?.description}</p>
  //                 </div>
  //               </div>
  //             </Link>
  //           ))
  //         ) : (
  //           <CustomEmpty description={<p> No Notifications Found...</p>} />
  //         )}
  //       </Skeleton>
  //     </div>
  //   </>
  // );

  return (
    <Header className="headerWrapper">
      <CustomRow>
        <CustomColumn xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* <div className="left"> */}
          <Link to={authenticatedRoutes.DASHBOARD} className="left">
            <div className="img">
              <img src={logo} alt="Profile" />
            </div>
            <h1 className="page-title">Portal</h1>
          </Link>
          {/* </div> */}
        </CustomColumn>

        <CustomColumn xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="userProfileWrapper">
            {/* <Popover
              placement={`${isMobileView ? "bottom" : "bottomRight"}`}
              title="Notifications"
              content={content}
              // onVisibleChange={(visible) => {
              //   if (visible === true) getNotification();
              // }}
              trigger="click"
            >
              <Badge
                count={NotificationsData ? NotificationsData?.length : "0"}
                overflowCount={10}
                color="#391f5b"
                showZero
              >
                <BellIcon />
              </Badge>
            </Popover> */}

            <Dropdown
              menu={{
                items,
              }}
              overlayClassName="profile-dropdown"
              onOpenChange={(visible) => setIsDropdownOpen(visible)}
              trigger={["click"]}
            >
              <span
                style={{
                  cursor: "pointer",
                }}
              >
                <div className="cont">
                  {/* <div className="profileImageWrapper">
                    <img src={profileImg} alt="Profile" />
                  </div> */}

                  <p>
                    {userObj?.name}{" "}
                    <FontAwesomeIcon
                      icon={isDropdownOpen ? faAngleUp : faAngleDown}
                      size="1x"
                    />
                  </p>
                </div>
              </span>
            </Dropdown>
          </div>
        </CustomColumn>
      </CustomRow>
    </Header>
  );
};

export default memo(TopHeader);
