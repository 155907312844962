import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  AllMenusItems,
  permissionsToRoutes,
} from "utils/Constants/routes.constant";
import CustomScrollbars from "components/CustomComponents/CustomScrollbars";
import useMobileView from "hooks/useMobileView";
import { useAuth } from "hooks/useAuth";
import "./Sidebar.less";

const Sidebar = (props) => {
  const { Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const isMobileView = useMobileView();
  const { userObj } = useAuth();

  console.log(userObj, "userObj");

  const activeMenuItemKey = AllMenusItems.find((item) =>
    location.pathname.startsWith(`/${item.linkTo}`)
  )?.selectedOptionKey;

  useEffect(() => {
    setCollapsed(true);
  }, [activeMenuItemKey]);

  const getMenu = (label, key, icon, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
  });

  // Filtering the menu items based on the permissions
  const filterMenuItemsByPermissions = (menuItems, permissions) => {
    if (!Array.isArray(permissions)) {
      return [];
    }
    return menuItems.filter((menuItem) => {
      const route = menuItem.linkTo;
      // Allow access if no requiredPermissions or an empty array
      const requiredPermissions = permissionsToRoutes[route] || [];
      if (requiredPermissions.length === 0) {
        return true;
      }
      return requiredPermissions.some((permission) =>
        permissions.includes(permission)
      );
    });
  };

  const userPermissions = userObj?.permissions || [];
  const filteredMenuItems = filterMenuItemsByPermissions(
    AllMenusItems,
    userPermissions
  );

  const items = filteredMenuItems.map((menu) =>
    getMenu(
      <Link to={menu?.linkTo}>
        {menu?.icon && <span className="menu-icon">{menu.icon}</span>}
        <span className="menu-icon-text">{menu.label}</span>
      </Link>,
      menu.selectedOptionKey
    )
  );

  return (
    <div className="sidebar-sec">
      <Sider
        collapsible
        breakpoint="lg"
        collapsedWidth={isMobileView ? "0px" : "55px"}
        width={isMobileView ? "180px" : "210px"}
        onCollapse={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
        {...props}
      >
        {/* <div className="logo">
          <a href="/">
            <img src={Logo} className="imgOnLogin" alt="Image" />
          </a>
        </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            theme="dark"
            className="custom-sidebar"
            selectedKeys={[activeMenuItemKey ? activeMenuItemKey : "dashboard"]}
            mode="inline"
            items={items}
          />
        </CustomScrollbars>
      </Sider>
    </div>
  );
};

export default Sidebar;
