import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Space } from "antd";
import moment from "moment";
import CustomGridView from "components/CustomComponents/CustomGridView";
import CustomRow from "components/UiComponents/CustomRow";
import CustomColumn from "components/UiComponents/CustomColumn";
import CustomButton from "components/UiComponents/CustomButton";
import { CustomPopConfirm } from "components/UiComponents/CustomPopConfirm";
import CustomStatusBadge from "components/UiComponents/CustomStatusBadge";
import { DATE_FORMAT, REPORT_STATUS } from "utils/Constants/Constant";
import { authenticatedRoutes } from "utils/Constants/routes.constant";
import { PageTitleContext } from "PageTitleContext";
import EditIcon from "assets/SvgFiles/EditIcon";
import DeleteIcon from "assets/SvgFiles/DeleteIcon";
import notificationService from "utils/Notification/Notification.service";
import { useDeleteReport } from "hooks/useReportApi";
import { useFetchService } from "hooks/useServiceApi";
import { useFetchContract } from "hooks/useContractApi";
import { SERVICE_URL } from "utils/Constants/ServiceUrl.constants";
import {
  getLabelById,
  getValueOrPlaceholder,
} from "utils/Helper/Services.helper";

const Report = () => {
  const navigate = useNavigate();
  const { setPageTitle } = useContext(PageTitleContext);
  const { pageTitle } = useContext(PageTitleContext);

  const { mutate: deleteReport } = useDeleteReport();

  const { data: serviceData } = useFetchService({
    limit: 10000,
  });
  const { data: contractData } = useFetchContract({
    limit: 10000,
  });

  const handleDelete = (id) => {
    deleteReport(id, {
      onSuccess: () => {
        notificationService.success(`Report Deleted`);
      },
      onError: (error) => {
        notificationService.error(`Error deleting report`);
      },
    });
  };

  useEffect(() => {
    setPageTitle("Reports");
  }, [setPageTitle]);

  const columns = [
    {
      title: "#",
      key: "sNo",
      render: (res, record, index) => `${index + 1}`,
    },
    {
      title: "Title",
      key: "title",
      render: (res) => getValueOrPlaceholder(res, "title"),
    },

    {
      title: "Contract Id",
      key: "contractId",
      render: (res) => {
        return getLabelById(contractData?.data?.items, res?.contractId);
      },
    },
    {
      title: "Service Id",
      key: "serviceId",
      render: (res) => {
        return getLabelById(serviceData?.data?.items, res?.serviceId);
      },
    },
    {
      title: "Attachments",
      key: "attachmentId",
      render: (res) => <span>Atachments ({res?.attachmentId?.length})</span>,
    },
    {
      title: "Description",
      key: "description",
      render: (res) => getValueOrPlaceholder(res, "description"),
    },
    {
      title: "Status",
      key: "status",
      className: "status-badge-col",
      render: (res) => (
        <CustomStatusBadge
          status={res?.status}
          contractStatus={REPORT_STATUS}
        />
      ),
    },
    {
      title: "Created On",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format(DATE_FORMAT),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },

    {
      title: "Action",
      key: "action",
      className: "action-col",
      render: (res) => (
        <Space size="middle">
          <CustomPopConfirm
            title="Are you sure you want to delete this report?"
            buttonChildren={<DeleteIcon />}
            onConfirm={() => handleDelete(res.id)}
          />
          <CustomButton
            htmlType="default"
            className="action-btn edit-icon textWhite"
            onClick={() => {
              navigate(`${authenticatedRoutes.EDIT}${res.id} `, {
                state: res,
              });
            }}
          >
            <EditIcon />
          </CustomButton>
        </Space>
      ),
    },
  ];

  return (
    <div className="wrapper reportMain">
      <CustomRow>
        <CustomColumn lg={24} md={24} sm={24} xs={24}>
          <CustomGridView
            listingApiRoute={SERVICE_URL.REPORT.GET_ALL_REPORTS}
            searchBar={true}
            searchCriteriaColumns={["title"]}
            // filterKeys={[
            //   {
            //     key: "",
            //     placeholder: "",
            //   },
            // ]}
            titleButton={true}
            btnText="Add New"
            btnAction={() => {
              navigate(authenticatedRoutes.ADD);
            }}
            columns={columns}
            pageTitle={pageTitle}
          />
        </CustomColumn>
      </CustomRow>
    </div>
  );
};

export default Report;
