import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./progressBar.less";

const ScrollProgressBar = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const location = useLocation();

  const updateScrollProgress = () => {
    const mainContentWrapper = document.getElementById("main-content-wrapper");
    if (mainContentWrapper) {
      const scrollPx = mainContentWrapper.scrollTop;
      const contentHeight =
        mainContentWrapper.scrollHeight - mainContentWrapper.clientHeight;
      const scrolled = (scrollPx / contentHeight) * 100;
      setScrollProgress(scrolled);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      updateScrollProgress();
    };

    const mainContentWrapper = document.getElementById("main-content-wrapper");
    if (mainContentWrapper) {
      mainContentWrapper.addEventListener("scroll", handleScroll);

      return () => {
        mainContentWrapper.removeEventListener("scroll", handleScroll);
        setScrollProgress(0);
        mainContentWrapper.scrollTo(0, 0);
      };
    }
  }, [location.pathname]);

  // let topbarSecHeight;
  // const topbarSec = document.querySelector(".headerWrapper");
  // if (topbarSec) {
  //   topbarSecHeight = topbarSec.offsetHeight;
  // }

  const progressBarStyle = {
    // top: topbarSec ? topbarSec.offsetHeight : 0,
    width: `${scrollProgress}%`,
    borderTopRightRadius: scrollProgress === 100 ? 0 : 50,
    borderBottomRightRadius: scrollProgress === 100 ? 0 : 50,
    opacity: scrollProgress === 0 ? 0 : scrollProgress / 30,
  };

  return <div className="progressBar" style={progressBarStyle}></div>;
};

export default ScrollProgressBar;
